import {  useTheme } from '@mui/material';
import { Box, Button } from '@mui/material';
import React, { useContext } from 'react';

import HomeHeader from './greetings-header/HomeHeader';
import GlobalHeader from './greetings-header/GlobalHeader';

const GreetingsHeader = ({ title, subtitle }) => {

  const isHomePage = window.location.pathname === '/';

  return (
    <Box sx={{ width:'100%' }}>

       {isHomePage ? (
        <HomeHeader title={title} subtitle={subtitle}  />
       ) : (
        <GlobalHeader title={title} subtitle={subtitle}  />
       )}
     
    </Box>
  );
};

export default GreetingsHeader;
