import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import InputMask from 'react-input-mask';
import GreetingsHeader from '../components/GreetingsHeader';
import Avatar from '@mui/material/Avatar';
import AuthContext from '../context/auth/AuthContext';
import {
  Box,
  Container,
  Grid,
  TextField,
  CircularProgress,
  ButtonGroup,
  Stack,
} from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';

import { List, ListItem, Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import { toast } from 'react-toastify';

function NestedListItem({ title, children, style, childStyle }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <ListItem
        component={'div'}
        sx={{ borderTop: '1px solid #edf5f3', py: 0, ...style }}
        disableGutters
      >
        <ListItemButton onClick={() => setOpen(!open)}>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <div style={{ ...childStyle }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <ListItem>{children}</ListItem>
        </Collapse>
      </div>
    </>
  );
}

function Profile() {
  const authContext = useContext(AuthContext);
  const { user, apiUrl, token, updateUser, whoAmI } = authContext;
  const [refreshed, setRefreshed] = useState(false);
  const [userImage, setUserImage] = useState(user.image);
  const [file, setFile] = useState(null);
  const inputRef = useRef(null);
  const [form, setForm] = useState({
    username: user.username,
    firstname: user.firstname,
    lastname: user.lastname,
    phone: user.phone,
    email: user.email,
    password: null,
    image: null,
    notifications: user.notifications ? user.notifications : false,
  });
  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setForm({ ...form, [name]: value });
  };

  useLayoutEffect(() => {
    whoAmI().then((x) => {
      setForm({
        ...form,
        username: x.username,
        firstname: x.firstname,
        lastname: x.lastname,
        phone: x.phone,
        email: x.email,
      });
      setUserImage(x.image);
      setRefreshed(true);
    });
    // eslint-disable-next-line
  }, []);

  const handleChangeFile = (e) => {
    const imageMimeType = /image\/(png|jpg|jpeg)/i;

    const file = e.target.files[0];
    if (!file.type.match(imageMimeType)) {
      alert('Image mime type is not valid');
      return;
    }
    setFile(file);
  };
  const handleNotifications = (e, x) => {
    setForm({ ...form, notifications: x });
  };
  const commonProps = {
    variant: 'outlined',
    size: 'small',
    sx: {
      // '& :before,& :after': { display: 'none' },
      '& .MuiOutlinedInput-root fieldset': {
        borderColor: 'rgba(255, 160, 160, 0.1)',
        borderRadius: 0,
      },
    },
  };

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (file) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setUserImage(result);
          setForm({ ...form, image: result });
        }
      };
      fileReader.readAsDataURL(file);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
    // eslint-disable-next-line
  }, [file]);
  const cancelImageChange = () => {
    setForm({ ...form, image: null });
    setFile(null);
    setUserImage(user.image);
    inputRef.current.value = null;
  };
  const handleUpdateProfile = async () => {
    await axios
      .post(`${apiUrl}/user/updateProfile`, form, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        toast.success('Profile Updated!');
        updateUser(data.data[0]);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };
  if (!refreshed) {
    return(
      <Stack direction="column" flexGrow={'1'} alignItems={'center'} justifyContent={'center'} sx={{ height:'100%' }}>
         <CircularProgress />
      </Stack>);
  }
  return (
    <Container
      maxWidth={false}
      sx={{
        px: { xs: '27px', md: '50px' },
      }}
    >
      <>
        <Box
          sx={{
            color: '#181818',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: { md: 'center' },
            fontSize: '.9em',
            position: 'relative',
            px: 0,
            pt: 3,
            pb: '81px',
          }}
        >
          <Container
            maxWidth={false}
            sx={{
              position: 'relative',
              px: '0!important',
            }}
          >
            <GreetingsHeader />
          </Container>
        </Box>
        {/* User Avatar */}
        <Grid container maxWidth={'md'} sx={{ mx: 'auto' }}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <Avatar
                alt={form.username}
                src={userImage}
                variant="square"
                sx={{
                  width: 150,
                  height: 150,
                  borderRadius: '8px',
                  '& .MuiAvatar-img': {
                    objectFit: 'contain',
                    backgroundColor: '#edf5f3',
                  },
                }}
              ></Avatar>
              <ButtonGroup>
                <IconButton
                  color="inherit"
                  aria-label="upload picture"
                  component="label"
                >
                  <input
                    hidden
                    accept="image/*"
                    onChange={handleChangeFile}
                    type="file"
                    ref={inputRef}
                  />
                  <PhotoCamera />
                </IconButton>
                {userImage !== user.image && (
                  <IconButton
                    onClick={cancelImageChange}
                    color="error"
                    component="label"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ButtonGroup>

              {/* User Firstname and Lastname under the avatar image */}
              <Typography
                component="h1"
                variant="h1"
                sx={{
                  fontSize: '2em',
                  lineHeight: '1.25em',
                  fontWeight: '800',
                  textTransform: 'capitalize',
                  color: 'primary.main',
                  marginBottom: '0.3em',
                }}
              >
                {user.firstname} {user.lastname}
              </Typography>
              <Typography
                component="h6"
                variant="h6"
                sx={{
                  fontSize: '1.1em',
                  lineHeight: '1.25em',
                  fontWeight: '500',
                  color: '#6F8BA4',
                  textTransform: 'lowercase',
                  marginBottom: '1.8em',
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <span>@</span>
                {user.username}
              </Typography>

              {/* User Details inputs */}
              <Box
                sx={{
                  mx: 'auto',
                  width: '100%',
                  maxWidth: '490px',
                }}
              >
                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  sx={{ marginBottom: '1.5em' }}
                >
                  <ListItem>
                    <ListItemText primary="First Name" />
                    <TextField
                      {...commonProps}
                      value={form.firstname}
                      name="firstname"
                      onChange={handleChange}
                    />
                  </ListItem>
                  <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                    <ListItemText primary="Last Name" />
                    <TextField
                      {...commonProps}
                      value={form.lastname}
                      name="lastname"
                      onChange={handleChange}
                    />
                  </ListItem>
                  <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                    <ListItemText primary="Phone Number" />
                    <InputMask
                      mask="(999) 999 9999"
                      onChange={handleChange}
                      value={form.phone}
                    >
                      {() => (
                        <TextField {...commonProps} name="phone" />
                      )}
                    </InputMask>
                  </ListItem>
                  <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                    <ListItemText primary="User Name" />
                    <TextField
                      {...commonProps}
                      value={form.username}
                      name="username"
                      onChange={handleChange}
                    />
                  </ListItem>
                  <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                    <ListItemText primary="Email" />
                    <TextField
                      {...commonProps}
                      name="email"
                      type="email"
                      value={form.email}
                      onChange={handleChange}
                    />
                  </ListItem>
                  <NestedListItem title="Reset Password">
                    <ListItemText primary="New Password" />
                    <TextField
                      {...commonProps}
                      type="password"
                      name="password"
                      value={form.password ? form.password : ''}
                      onChange={handleChange}
                    />
                  </NestedListItem>
                  <ListItem sx={{ borderTop: '1px solid #edf5f3' }}>
                    <ListItemText primary="Notifications" />
                    <Switch
                      checked={form.notifications}
                      onChange={handleNotifications}
                    />
                  </ListItem>
                </List>
                <Button
                  variant="contained"
                  sx={{
                    fontFamily: '"Poppins", sans-serif',
                    textTransform: 'uppercase',
                    fontSize: '1.3em',
                    width: '100%',
                    borderRadius: '50px',
                    fontWeight: '500',
                  }}
                  onClick={handleUpdateProfile}
                >
                  DONE
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </>
    </Container>
  );
}

export default Profile;
