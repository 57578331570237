import React, { useContext, useState } from 'react';

import Legal from '../../components/Legal';
import AuthContext from '../../context/auth/AuthContext';

import {
  Link as RouterLink,
  useParams,
  useNavigate,
} from 'react-router-dom';

import {
  Avatar,
  // Button,
  CssBaseline,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  alpha,
} from '@mui/material';

import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { toast } from 'react-toastify';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@emotion/react';

const identifier = window.location.hostname;
function ResetPw() {
  const theme = useTheme();
  const { token } = useParams();
  const { setLoading, apiUrl, loading } = useContext(AuthContext);
  const [state, setState] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const validate = () => {
    setErrors([]);
    let newErrors = [];
    if (!state.password) {
      newErrors.push({
        name: 'password',
        error: 'The Password Field is Required',
      });
    }
    if (!state.passwordConfirmation) {
      newErrors.push({
        name: 'password',
        error: 'The Confirm Password Field is Required',
      });
    }
    if (state.password && state.passwordConfirmation) {
      if (state.password !== state.passwordConfirmation) {
        newErrors.push({
          name: 'password',
          error: 'The Password must match the confirmation',
        });
      }
    }
    newErrors.forEach((x) => {
      toast.error(x.error);
    });
    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      sendResetPassword(state);
    }
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };
  let navigate = useNavigate();
  // send reset password request to the server
  const sendResetPassword = async (data) => {
    setLoading(true);
    axios
      .post(`${apiUrl}/password/reset`, {
        domain: identifier,
        password: state.password,
        token: token,
      })
      .then((res) => {
        toast.success('Password Updated!');
        return navigate('/login', { replace: true });
      })
      .catch((res) => {
        res = res.response;
        if (res.data.error) {
          let errorsArray = [];
          Object.entries(res.data.error).forEach((x) => {
            let fieldName = x[0];
            let fieldValueArray = x[1];
            fieldValueArray.forEach((err) => {
              toast.error(err);
            });
            errorsArray.push({
              name: fieldName,
            });
            setErrors(errorsArray);
            if (fieldName === 'password') {
              setState({
                ...state,
                password: '',
                passwordConfirmation: '',
              });
            }
          });
        } else {
          toast.error(res.data.message);
        }

        setLoading(false);
      });
    return;
  };

  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: { xs: '16px', md: '35px' },
    lineSeight: '100%',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: { xs: '64px', md: '98px' },
    textTransform: 'none',
  };

  const TextFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '50px',
      borderColor: '#fff',
      height: { xs: '50px', md: '70px' },
      pl: { xs: '22px', md: '30px' },
      alignItems: 'flex-end',
      pb: { xs: '7px', md: '15px' },
      '& legend': { display: 'none' },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover svg': {
        color: 'primary.main',
      },
    },
    '& .MuiFormLabel-root': {
      top: { xs: '10px', md: '14px' },
      left: { xs: '8px', md: '17px' },
    },
    '& .Mui-focused': {
      borderColor: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent!important',
      },
    },
    '& .MuiInputBase-input': {
      p: '0',
    },
  };
  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={7}
        sx={{
          position: { xs: 'fixed', sm: 'relative' },
          bottom: { xs: '0px', sm: 'initial' },
          width: { xs: '100%', sm: 'initial' },
          backgroundImage:
            'url(https://source.unsplash.com/random?fitness)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '100%', sm: 'auto' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.blackBg.main,
              0
            )} 0%, ${theme.palette.blackBg.main} 47.84%)`,

            sm: theme.palette.common.black,
          },
          boxShadow: 'none',
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: '1',
            height: { xs: 'auto', sm: '100%' },
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
            }}
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              sx={{
                my: 8,
                mb: { xs: '12px', md: '64px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexGrow: '0',
                maxWidth: '513px',
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    color: 'white.main',
                    fontSize: { xs: '40px', md: '53px' },
                  }}
                  align="center"
                  mb={{ xs: 1, md: '7vh' }}
                >
                  Set Password
                </Typography>
              </Box>

              <Box sx={{ width: '100%', mt: '2rem' }}>
                <TextField
                  margin="none"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={state.password}
                  onChange={handleChange}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: <LockOpenIcon sx={{ mr: 2 }} />,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                  error={
                    errors.find((x) => x.name === 'password')
                      ? true
                      : false
                  }
                  helperText={
                    errors.find((x) => x.name === 'password')
                      ? errors.find((x) => x.name === 'password')
                          .error
                      : null
                  }
                  sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  name="passwordConfirmation"
                  label="Confirm Password"
                  type={showPassword ? 'text' : 'password'}
                  id="passwordConfirmation"
                  value={state.passwordConfirmation}
                  onChange={handleChange}
                  autoComplete="off"
                  InputProps={{
                    startAdornment: <LockOpenIcon sx={{ mr: 2 }} />,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                  error={
                    errors.find(
                      (x) => x.name === 'passwordConfirmation'
                    )
                      ? true
                      : false
                  }
                  helperText={
                    errors.find(
                      (x) => x.name === 'passwordConfirmation'
                    )
                      ? errors.find(
                          (x) => x.name === 'passwordConfirmation'
                        ).error
                      : null
                  }
                  sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
                />

                <LoadingButton
                  type="submit"
                  loading={loading}
                  fullWidth
                  variant="contained"
                  sx={[btnStyle, { mb: { xs: '20px', md: 3 } }]}
                >
                  Save
                </LoadingButton>

                <Grid container>
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/login"
                      variant="body2"
                      color="white.secondary"
                    >
                      {'Already have an account? Sign In'}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              my: 1,
              mx: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'end',
            }}
          >
            <Legal />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ResetPw;
