import React, { useContext, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import AuthContext from '../../context/auth/AuthContext';
import Legal from '../../components/Legal';

import {
  Grid,
  Typography,
  Paper,
  Box,
  Link,
  TextField,
  CssBaseline,
  Avatar,
  alpha,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LoadingButton from '@mui/lab/LoadingButton';
import EmailIcon from '@mui/icons-material/Email';
import { toast } from 'react-toastify';
import { useTheme } from '@emotion/react';

export default function ForgotPw() {
  const theme = useTheme();
  const { resetPw, loading } = useContext(AuthContext);
  const [state, setState] = useState({
    email: '',
  });

  const [errors, setErrors] = useState([]);

  const validate = () => {
    setErrors([]);
    let newErrors = [];
    if (!state.email) {
      newErrors.push({
        name: 'email',
        error: 'The Email Field is Required',
      });
    }
    if (state.email && !validateEmail(state.email)) {
      newErrors.push({
        name: 'email',
        error: 'The Email is invalid',
      });
    }
    newErrors.forEach((x) => {
      toast.error(x.error);
    });
    return newErrors;
  };
  const validateEmail = (email) => {
    return email.match(
      //eslint-disable-next-line
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = validate();
    if (errors.length > 0) {
      setErrors(errors);
    } else {
      resetPw(state);
      setState({ email: '' });
    }
  };
  const handleChange = (e) => {
    setState({ email: e.target.value });
  };

  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: { xs: '16px', md: '35px' },
    lineSeight: '100%',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: { xs: '64px', md: '98px' },
    textTransform: 'none',
  };

  const TextFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '50px',
      borderColor: '#fff',
      height: { xs: '50px', md: '70px' },
      pl: { xs: '22px', md: '30px' },
      alignItems: 'flex-end',
      pb: { xs: '7px', md: '15px' },
      '& legend': { display: 'none' },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover svg': {
        color: 'primary.main',
      },
    },
    '& .MuiFormLabel-root': {
      top: { xs: '10px', md: '14px' },
      left: { xs: '8px', md: '17px' },
    },
    '& .Mui-focused': {
      borderColor: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent!important',
      },
    },
    '& .MuiInputBase-input': {
      p: '0',
    },
  };

  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <CssBaseline />
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={7}
        sx={{
          position: { xs: 'fixed', sm: 'relative' },
          bottom: { xs: '0px', sm: 'initial' },
          width: { xs: '100%', sm: 'initial' },
          backgroundImage:
            'url(https://source.unsplash.com/random?fitness)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '100%', sm: 'auto' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.blackBg.main,
              0
            )} 0%, ${theme.palette.blackBg.main} 47.84%)`,

            sm: theme.palette.common.black,
          },
          boxShadow: 'none',
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: '1',
            height: { xs: 'auto', sm: '100%' },
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
            }}
          >
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                my: 8,
                mb: { xs: '12px', md: '64px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexGrow: '0',
                maxWidth: '513px',
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    color: 'white.main',
                    fontSize: { xs: '40px', md: '53px' },
                  }}
                  align="center"
                  mb={{ xs: 1, md: '7vh' }}
                >
                  Forgot Password?
                </Typography>
              </Box>

              <Box sx={{ width: '100%', mt: '2rem' }}>
                <TextField
                  margin="normal"
                  required
                  type="email"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  autoComplete="off"
                  autoFocus
                  InputProps={{
                    startAdornment: <EmailIcon sx={{ mr: 2 }} />,
                  }}
                  error={
                    errors.find((x) => x.name === 'email')
                      ? true
                      : false
                  }
                  helperText={
                    errors.find((x) => x.name === 'email')
                      ? errors.find((x) => x.name === 'email').error
                      : null
                  }
                  sx={[TextFieldStyle, { mb: { xs: '10px', md: 2 } }]}
                />

                <LoadingButton
                  loading={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  size="large"
                  sx={[btnStyle, { mb: { xs: '20px', md: 3 } }]}
                >
                  Email Reset Link
                </LoadingButton>
                <Grid container>
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/login"
                      variant="body2"
                      color="white.secondary"
                    >
                      {'Login'}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              my: 1,
              mx: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'end',
            }}
          >
            <Legal />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
