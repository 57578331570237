import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import {
  Link,
  Link as RouterLink,
  useParams,
} from 'react-router-dom';

import AuthContext from '../../../context/auth/AuthContext';
import WorkoutVideoPortrait from './components/portrait/WorkoutVideoPortrait';
import WorkoutVideoLandscape from './components/landscape/WorkoutVideoLandscape';

const identifier = window.location.hostname;

const CategoryPlayVideos = () => {
  const params = useParams();
  const [category, setCategory] = useState(null);
  const [video, setVideo] = useState(null);
  const [allTags, setAllTags] = useState(null);
  const authContext = useContext(AuthContext);
  const [allVideos, setAllVideos] = useState([]);
  const [isPlaying, setIsPlaying] = useState();

  const [orientationLandscape, setOrientationLandscape] =
    useState(false);
  const { apiUrl, token } = authContext;
  const [totalVideos, setTotalVideos] = useState();

  const getCategory = async (id) => {
    return await axios.get(
      `${apiUrl}/v2/categories/${id}?reseller_domain=${identifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  //get the category
  useEffect(() => {
    if (!params.cat_id || category) return;
    //get the category
    getCategory(params.cat_id).then((res) => {
      setCategory(res?.data?.data[0]);
    });
  });

  //get the video to show based on vid_id param
  useEffect(() => {
    if (!category) return;
    let allVideosArray = getVideos(category);

    setAllVideos(allVideosArray);

    //set the first video from array
    setVideo(allVideosArray[0]);
  }, [category]);

  const getVideos = (cat) => {
    let videos = [];
    const traverse = (subcategory) => {
      ifKeyData(subcategory);
    };
    const ifKeyData = (subcategory) => {
      if (subcategory.videos) {
        videos = videos.concat(subcategory.videos);
      }
      if (subcategory.subcategories) {
        subcategory.subcategories.forEach(traverse);
      }
    };
    ifKeyData(cat);
    return videos;
  };

  const filterAllVideos = (id) => {
    const foundVideos = allVideos.filter((video) => video.id === id);
    return foundVideos[0];
  };

  useEffect(() => {
    if (!category) {
      return;
    }

    //set the total videos number to show
    if (allVideos.length === 0 || allVideos.length > 1) {
      setTotalVideos(allVideos.length);
    } else if (allVideos.length === 1) {
      setTotalVideos(allVideos.length);
    }
  }, [allVideos]);

  // get the tags
  useEffect(() => {
    let uniqueTagsArray = [];

    if (video && 'tags' in video && video.tags.length > 0) {
      video.tags.forEach((tag) => {
        uniqueTagsArray.push(tag);
      });
      setAllTags(uniqueTagsArray);
    }
  }, [video]);

  useEffect(() => {
    [
      'fullscreenchange',
      'webkitfullscreenchange',
      'mozfullscreenchange',
      'msfullscreenchange',
    ].forEach((eventType) =>
      document.addEventListener(eventType, (event) => {

        if (
          orientationLandscape &&
          !(
            (document.fullscreenElement &&
              document.fullscreenElement !== null) ||
            (document.webkitFullscreenElement &&
              document.webkitFullscreenElement !== null) ||
            (document.mozFullScreenElement &&
              document.mozFullScreenElement !== null) ||
            (document.msFullscreenElement &&
              document.msFullscreenElement !== null)
          )
        ) {
          setOrientationLandscape(false);
        }
      })
    );
  });
  
 //set data for saving the calories 
const [videoStartTime, setVideoStartTime] = useState(0);
const [videoPauseTime, setVideoPauseTime] = useState(0);
const [videoDuration, setVideoDuration] = useState(0);
const [redirectHome, setRedirectHome] = useState(false);

 

  // get child component data
  const handleCallbackVideoData = async (childData) => {
    const { orientationLandscape, currentVideo, videoDuration, open,redirectHome } = childData || {};
    if (orientationLandscape !== undefined) setOrientationLandscape(orientationLandscape);
    if (currentVideo !== undefined) setVideo(currentVideo);
    if (videoDuration !== undefined) setVideoDuration(videoDuration);
    if (open !== undefined) setIsPlaying(open);
    if (redirectHome === true) setRedirectHome(true);
  };

  useEffect(() => {
   
    if(isPlaying === true){
       setVideoStartTime(Date.now())
    }else {
      if(videoStartTime > 0)
      setVideoPauseTime(Date.now())
    }
  
  }, [isPlaying])


  useEffect(() => {
   if(videoPauseTime && videoPauseTime !== 0 )
      calculateCalories()
  }, [videoPauseTime])
  
  const calculateCalories = () => {
    if (videoPauseTime !== 0  && videoStartTime !==0) {
      const elapsedTime = (videoPauseTime - videoStartTime) / 1000;    

      if(elapsedTime >= 10 ) {
        const kcalSecondsPlayed =Math.round((video.kcal  /  videoDuration ) * elapsedTime);
        saveCalories(kcalSecondsPlayed);
      }else if(elapsedTime <=  10 && redirectHome) {
        //if pressed on backButon (child var redirectHome === true) , redirect to home
        window.location.href='/';
      }
    
    }
  }
  
  const saveCalories = async (calories) => {
    console.log(calories)
      await axios.post(
        `${apiUrl}/v2/user-update-history`,
        { domain:identifier, 
          calories: calories },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ) .then((res) => {
          //if pressed on backButon (child var redirectHome === true) , redirect to home
        if(res && redirectHome)  {
            window.location.href='/';
        }
      })
      .catch((err) => {
        console.error(err);       
      });
  }


  //get intensityCategories
  const [intensityCategories, setIntensityCategories] = useState(false);
//get intensityCategories videos might have
const getIntensityCategories = async () => {
  return await axios.get(
    `${apiUrl}/v2/video-intensities?reseller_domain=${identifier}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
}; 

useEffect(() => {

  if(!intensityCategories)

    getIntensityCategories().then((res) => {
      const intCat = res?.data?.data;
     setIntensityCategories(intCat)
    })
    
//eslint-disable-next-line
}, [intensityCategories])



  return (
    <>
      {!video ? (
        !category ? (
          <Stack
            direction="column"
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <CircularProgress />
          </Stack>
        ) : (
          <Stack
            direction="column"
            sx={{
              flexGrow: 1,
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            No videos found in this workout
          </Stack>
        )
      ) : (
        <>
          {!orientationLandscape ? (
            <WorkoutVideoPortrait
              category={category}
              videoPlaying={video}
              allTags={allTags}
              orientationLandscape={orientationLandscape}
              totalVideos={totalVideos}
              allVideos={allVideos}
              isPlaying={isPlaying}
              parentCallback={handleCallbackVideoData}
              intensityCategories={intensityCategories}
            />
          ) : (
            <WorkoutVideoLandscape
              category={category}
              videoPlaying={video}
              allTags={allTags}
              orientationLandscape={orientationLandscape}
              totalVideos={totalVideos}
              allVideos={allVideos}
              isPlaying={isPlaying}
              parentCallback={handleCallbackVideoData}
            />
          )}
        </>
      )}
    </>
  );
};

export default CategoryPlayVideos;
