import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import {
  Card,
  CardContent,
  Box,
  IconButton,
  LinearProgress,
  Button,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
//icons
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import VideoDetails from './VideoDetails';
import VideoDetailsMobile from './VideoDetailsMobile';

import {  useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material';
import axios from 'axios';
import AuthContext from '../../context/auth/AuthContext';
import { useContext } from 'react';
import WorkoutVideoLandscape from '../../pages/categories/category/components/landscape/WorkoutVideoLandscape';
import { ifMainColorIsLight } from '../../helpers/functions';


import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';


const identifier = window.location.hostname;

function VideoItem({
  vid,
  isRecent,
  progress,
  categoriesCallback,
  pageType,
  height = '100%',
  style,
  scheduleCallback
}) {

  //   const playerRef = useRef(null);
  //set the dialog state
  const [openModal, setOpenModal] = React.useState(false);
  const theme = useTheme();
  const [watchedVideos, setWatchedVideos] = useState([]);
  const [duration, setDuration] = useState(100);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isIntensity, setIsIntensity] = useState(false);

  const authContext = useContext(AuthContext);
  const { apiUrl, token, } = authContext;

  //detect sm breakPoint
  const breakPointMD = useMediaQuery(theme.breakpoints.down('md'));

  const playerRef = React.useRef();

  useEffect(() => {
    if(vid  && 'intensity' in vid && 'id' in vid.intensity){
     
    setIsIntensity(vid.intensity.id? true : false)
    }
  }, [vid])


  //handle opening/closing Modal
  const handleModalOpen = () => {
    setOpenModal(true);   
  };

  const handleModalClose = () => {
    setOpenModal(false);    

    //send recently watched videos to parent in order to refresh video list
    if (categoriesCallback && watchedVideos.length > 0) {
      categoriesCallback({watchedVideos: watchedVideos});
    }
    if (scheduleCallback && watchedVideos.length > 0) {
      scheduleCallback({watchedVideos: watchedVideos});
    }
  
  };

  useEffect(() => {

    //set isVideoPlaying to false when user closes modal
    if(openModal === false) 
    setIsVideoPlaying(openModal)
  
  }, [openModal])
  
  //get workoutVideoLandscape child data (if video is playing or not)
  const handleVideoChildData = async (childData) => {
    if (childData) {
      setOpenModal(childData);
    }
  };

   //set data for saving the calories 
  const [videoStartTime, setVideoStartTime] = useState(0);
  const [videoPauseTime, setVideoPauseTime] = useState(0);

  //start/stop video recording time session depending on the video playing or not
  useEffect(() => {
    
    if(isVideoPlaying === true){
        setVideoStartTime(Date.now())
    }else {
      if(videoStartTime > 0)
      //set this in order to refresh calculator data
      localStorage.setItem('RefreshData',true);
      setVideoPauseTime(Date.now())
    }
   
      //eslint-disable-next-line
  }, [isVideoPlaying])

//calculate calories when user pauses/stops video
  useEffect(() => {
   if(videoPauseTime && videoPauseTime !== 0 )
      calculateCalories()
      //eslint-disable-next-line
  }, [videoPauseTime])
  
  //calculate calories depending on time spent
  const calculateCalories = () => {
    if (videoPauseTime !== 0  && videoStartTime !==0) {
      //get the time it played
      const elapsedTime = (videoPauseTime - videoStartTime) / 1000;    

      if(elapsedTime >= 10 ) {
        //if passed more than 10 secs, calculate calories 
        const kcalSecondsPlayed =Math.round((vid.kcal  /  duration ) * elapsedTime);
  
        saveCalories(kcalSecondsPlayed);      
      }    
    }
  }
  

  //save calories data in db
  const saveCalories = async (calories) => {
    console.log(calories)
      await axios.post(
        `${apiUrl}/v2/user-update-history`,
        { domain:identifier, 
          calories: calories },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )   
      .catch((err) => {
        console.error(err);       
      });
  }

    // get child component data
    const handleCallbackVideoData = async (childData) => {
      const { videoDuration, watchedVideos, open } = childData || {};
    
      if (watchedVideos  !== undefined)   setWatchedVideos(watchedVideos);
      if (videoDuration  !== undefined)   setDuration(videoDuration);
      if (open !== undefined)   setIsVideoPlaying(open);    

    };

  //fullscreen Mode

    const [orientationLandscape, setOrientationLandscape] =  useState(false);

    //detect when fullscreen changes
    useEffect(() => {
      [
        'fullscreenchange',
        'webkitfullscreenchange',
        'mozfullscreenchange',
        'msfullscreenchange',
      ].forEach((eventType) =>
        document.addEventListener(eventType, (event) => {
  
          if (
            orientationLandscape &&
            !(
              (document.fullscreenElement &&
                document.fullscreenElement !== null) ||
              (document.webkitFullscreenElement &&
                document.webkitFullscreenElement !== null) ||
              (document.mozFullScreenElement &&
                document.mozFullScreenElement !== null) ||
              (document.msFullscreenElement &&
                document.msFullscreenElement !== null)
            )
          ) {
            setOrientationLandscape(false);
          }
        })
      );
    });


   //lock &unlock orientation
   const lock = async () => {
    try {
      await ScreenOrientation.lock({
        type: OrientationType.LANDSCAPE,
      });
    } catch (error) {
      return false;
    }
  };

  const unlock = async () => {
    try {
      await ScreenOrientation.unlock();
    } catch (error) {
      return false;
    }
  };

  //change the fullscreen mode
  const changeOrientation = () => {
 
    let isInFullScreen =
      (document.fullscreenElement &&
        document.fullscreenElement !== null) ||
      (document.webkitFullscreenElement &&
        document.webkitFullscreenElement !== null) ||
      (document.mozFullScreenElement &&
        document.mozFullScreenElement !== null) ||
      (document.msFullscreenElement &&
        document.msFullscreenElement !== null);

    let docElm = document.documentElement;
    if (!isInFullScreen && !orientationLandscape) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      } else if (docElm.webkitEnterFullscreen) {
        docElm.webkitEnterFullscreen();
      }

      if (lock() ) lock();
      setOrientationLandscape(true)
    } else if (orientationLandscape) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }

      setOrientationLandscape(false)
      if (unlock()) unlock();
    }
  };



  //css
  const playerContainer = {
    height: {
      xs: pageType === 'schedule' ? '300px' : '250px',
      sn: pageType === 'schedule' ? '318px' : '250px',
      md: '250px',
      lg: '250px',
      xl: '318px',
    },
    width: '100%',
    position: 'relative',
  };
  const playerThumbnail = {
    height: '100%',
    width: '100%',
    '& div': {
      '& div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& iframe': {
          width: '593px!important',
          minWidth: '100%',
          minHeight: '100%',
        },
      },
    },
  };

  return (
    <>
      <Box sx={{ width: '100%', ...style }}>
        <Card
          className="video-card"
          title={vid.title}
          sx={{
            boxShadow: 'none',
            borderRadius: {
              xs: pageType === 'schedule' ? '24px' : '12px',
              md: '12px',
            },
            height: '100%',
          }}
        >
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              p: '0!important',
            }}
            className="card-content-video"
          >
            <Box sx={playerContainer}>
              {pageType === 'schedule' && breakPointMD ? (
                ''
              ) : (
                <div className="play-button">
                  <IconButton
                    aria-label="play/pause"
                    onClick={handleModalOpen}
                    sx={{
                      backgroundColor: alpha(
                        theme.palette.primary.main,
                        0.43
                      ),
                      '&::before': {
                        border: '1px solid',
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                      <PlayArrowIcon
                        sx={{
                          height: { xs: 38, md: 44, lg: 50, xl: 60 },
                          width: { xs: 38, md: 44, lg: 50, xl: 60 },
                        }}
                      />
                  </IconButton>
                </div>
              )}
              <Box className="player" sx={playerThumbnail}>
                <ReactPlayer
                  url={`https://www.vimeo.com/${vid.vimeo_id}`}
                  controls={false}
                  width="100%"
                  height={height}
                  ref={playerRef}
                  light={true}
                  playIcon={' '}

                  // onReady={handleReady}
                />
              </Box>
            </Box>

            {breakPointMD && pageType === 'schedule' ? (
              <></>
            ) : (
              <VideoDetails vid={vid} isIntensity={isIntensity}/>
            )}
          </CardContent>
          {isRecent ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress
                variant="determinate"
                value={progress}
              />
            </Box>
          ) : (
            ''
          )}

          {breakPointMD && pageType === 'schedule' ? (
            <VideoDetailsMobile
              vid={vid}
              videoDialog={handleVideoChildData} 
              isIntensity={isIntensity}
            />
          ) : (
            <></>
          )}
        </Card>
      </Box>
      <Dialog
        fullScreen={true}
        open={openModal}
        onClose={handleModalClose}
        sx={{
          '& .MuiPaper-root': { backgroundColor: 'common.white' },
        }}
      >
        <DialogContent
          sx={{
            padding: 0,
            height:'100%',
            '&:hover .vidTitle,&:active .vidTitle': {
              opacity: 1,
            },
          }}
        >
            {/* fullscreenMode */}
            <Button
              size="small"
              variant="text"
              onClick={changeOrientation}
              sx={{
                alignItems: 'center',
                display: 'flex',
                padding: '0',
                borderRadius: '15px',
                borderWidth: '0px',
                fontSize: '16px',
                color: theme.palette.drawerBg.main,
                textTransform: 'initial',
                position: 'absolute',
              top: '0',
              left: '0',
              zIndex: 9999999,
                justifyContent: {
                  xs: 'center',
                  md: 'flex-end',
                },
                lineHeight: 'initial',
                marginTop: '20px!important',
                '&:hover ': {
                  background: 'none',
                },
                '& svg': {
                  color: ifMainColorIsLight(theme),
                  fill: ifMainColorIsLight(theme),
                  width: '33px',
                  height: '33px',
                },

                '&:hover svg': {
                  color: theme.palette.drawerBg.main,
                  fill: theme.palette.drawerBg.main,
                },
              }}
            >
                {!orientationLandscape ? (
                  <OpenInFullIcon
                    htmlColor={
                      theme.palette.common.white
                    }
                    fontSize="large"
                  />
                ):(
                  <CloseFullscreenIcon
                    htmlColor={
                      theme.palette.common.white
                    }
                    fontSize="large"
                  />
                )}
          </Button>

          {/* close modal button */}
           {!orientationLandscape &&(
            <IconButton
              variant="text"
              sx={{
                position: 'absolute',
                top: '0',
                right: '0',
                zIndex: 9999999,
                borderRadius: '0',
                '& svg': {
                  color: ifMainColorIsLight(theme),
                  fill: ifMainColorIsLight(theme),
                  width: '33px',
                  height: '33px',
                },

                '&:hover svg': {
                  color: theme.palette.drawerBg.main,
                  fill: theme.palette.drawerBg.main,
                },
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          {/* video title */}
          {/* <Box
            className="vidTitle"
            sx={{
              width: '90%',
              ml: '5%',
              py: 3,
              position: 'absolute',
              top: '0',
              left: '0',
              zIndex: 4,
              borderRadius: '0',
              color: '#fff',
              backgroundColor: 'rgba(0,0,0,0.3)',
              opacity: 0,
              transition: ' all 0.3s ease-in',
              pl: 3,
            }}
          >
            <Typography component="h2">{vid.title}</Typography>
          </Box> */}
          <WorkoutVideoLandscape    
             
              videoPlaying={vid}
              orientationLandscape={true}
              isPlaying={isVideoPlaying}
              parentCallback={handleCallbackVideoData}
            />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default VideoItem;
