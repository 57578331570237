import { Avatar, CircularProgress, IconButton, Tooltip,useTheme,Box, Stack, Typography, useMediaQuery, TextField, FormControl } from '@mui/material';
import React from 'react';
import { useState } from 'react';

import { useEffect } from 'react';
import BasicModal from '../BasicModal';
import axios from 'axios';
import dayjs from 'dayjs';
import CountUp from '../../helpers/CountUp';
//icons
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { toast } from 'react-toastify';
import CircularAvatar from './CircularAvatar';
import { useLayoutEffect } from 'react';
import { useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';
import { ifMainColorIsLight, isLight } from '../../helpers/functions';



import CaloriesHistory from './CaloriesHistory';



const identifier = window.location.hostname;
const nowDate =   dayjs().format('YYYY-MM-DD');

const UserCalories = ({callback}) => {
  
    const [userImage,setUserImage]= useState();
    const theme = useTheme();
    const authContext = useContext(AuthContext);
    const {  apiUrl, token, whoAmI} = authContext;
    //get calories
    const [caloriesData, setCaloriesData] = useState({});
    //calories types
    const [goalCalories, setGoalCalories] = useState(0);
    const [foodCalories, setFoodCalories] = useState(0);
    const [exerciseCalories, setExerciseCalories] = useState(0);
    const [remainingCalories, setRemainingCalories] = useState(0);
    
    //if localstorage calories saved 
    const [refreshData, setRefreshData] = useState(false);

    //circular progress achieved calories
    const [sliderAchieved, setSliderAchieved] = useState(0);

    const getCalories = async () => {
        return await axios.get(
          `${apiUrl}/v2/user-calories?domain=${identifier}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      };

      useEffect(() => {      
        //check if calories from single item videos were saved
        setTimeout(() => {
            setRefreshData(localStorage.getItem('RefreshData'))            
        }, 500);        
            
    });


    //get user image
    useLayoutEffect(() => {
        whoAmI().then((x) => {
            setUserImage(x?.image? x.image : '');
            callback({userImage:x.image})
        });
        // eslint-disable-next-line
      }, []);

    
    useEffect(() => {
      
      //get new caloriesData
        if(refreshData){
            getCalories().then((res) => {
                const calData = res?.data?.data;
                setCaloriesData(calData)
                });
            localStorage.removeItem('RefreshData');
            setRefreshData(false)
        }
         // eslint-disable-next-line
    }, [refreshData])

    useEffect(() => {
        //get calories data
        if(Object.keys(caloriesData).length === 0){
            getCalories().then((res) => {
            const calData = res?.data?.data;
                
            setCaloriesData(calData)
            });
        } 
        //set calories types
        if(Object.keys(caloriesData).length > 0){
          
            if( 'goal_calories' in caloriesData)  setGoalCalories(caloriesData.goal_calories) 
            if( 'food_calories' in caloriesData)  setFoodCalories(caloriesData.food_calories)            
            if('history' in caloriesData && caloriesData.history != null && caloriesData.history[nowDate] )  setExerciseCalories(caloriesData.history[nowDate]) 
                       
        }   

     //eslint-disable-next-line
    }, [caloriesData])

    //set remainig and achieved calories
    useEffect(() => {
       //set remaining
        const caloriesRemaining = Math.round(goalCalories + foodCalories - exerciseCalories);
        const caloriesRemainingValidated = caloriesRemaining < 0 ? 0 : caloriesRemaining  
    
        setRemainingCalories(caloriesRemainingValidated);


        //set achieved
        if ((goalCalories === 0 && foodCalories === 0)  || caloriesRemainingValidated === 0) { 

            setSliderAchieved(100);
             return
        }

        let sliderVal =exerciseCalories * 100 / (goalCalories + foodCalories);  
        if(sliderVal > 100 ) sliderVal = 100;

        setSliderAchieved(sliderVal);
          
        callback({goalCalories:goalCalories})
             //eslint-disable-next-line
    }, [goalCalories,foodCalories,exerciseCalories]);
    
    useEffect(() => {
      callback({sliderAchieved: sliderAchieved} )
           //eslint-disable-next-line
    }, [sliderAchieved])
    

    //set  Modal data
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    
    const [formData, setFormData] = useState({
        goal: 0,
        food: 0,
    });
    
    const handleModalOpen = () => {
        setModalOpen(true)    
    };
    
    //set Modal Data
    useEffect(() => {
       
        if(modalOpen)
            setModalData({
                title:'Set Calories',
                openVal:modalOpen,
                parentCallback:handleCallbackModalData,
                dialogFields : <DialogFields/>,
                onSubmit:saveGoals,
            })
        else setModalData({})

    }, [modalOpen])
    
    //get the modal open value from modal
    const handleCallbackModalData = async (childData) => {
      
        if ( childData && 'modalOpen' in childData) {      
            setModalOpen(childData.modalOpen);
        }
    };
    
    //update states with input values
    const handleInputChange = (e) => {

        setFormData((prevFormData) => ({ ...prevFormData, [e.target.name]: e.target.value }));

      };
      //when formData is filled, send data to modal
    useEffect(() => {
        setModalData({
            ...modalData,
            dataSubmit:formData
        })
    }, [formData])
    
    //save calories goals & food
    const saveGoals = async (data) => {
     
        if(!data || data === null || (!data.goal && !data.food)) {
            toast.error('Please set calories');
            return;
        }

        //set a new array to send only the modified values
        let caloriesGoals = {}
        caloriesGoals.domain = identifier

        //add data to array
        if (data.goal && data.goal !== '')  caloriesGoals.goal_calories=data.goal;
        if (data.food && data.food !== '') caloriesGoals.food_calories=data.food;

        
        await axios.post(
          `${apiUrl}/v2/user-update-goals`,
          caloriesGoals,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((res) => {
          
            if (res) {

                const response = res.data;         
                toast.success('Calories saved successfully');
                
                //after saving , refresh calories data in page
                getCalories().then((res) => {

                    const calData = res?.data?.data;                      
                    setCaloriesData(calData)
                });

                //close modal
                setModalOpen(false)               
            }
        }) 
        .catch((err) => {
            const error = err.response.data.error;
            //for each error, show toast
            Object.keys(error).forEach((key) => {
                const messages = error[key];
                if (Array.isArray(messages)) {
                    messages.forEach((message) => {
                        toast.error(message);
                    });
                } else {
                    toast.error(messages);
                }
            });
    
          console.error(err);       
        });
    }


    //define inputs for modal
      const inputs = [
        {
          name: 'goal',
          label: 'Set Goal Calories',
          id: 'goal',
          icon: '',
          required: false,
          type:'number',
        },
        {
            name: 'food',
            label: 'Set Food Calories',
            id: 'food',
            icon: '',
            required: false,
            type:'number',
          },
    ]


    //set dialog input fields
    const DialogFields = () => {
        return (
            <div>
            
                 {/* generate input fields */}
                 {inputs.map((input, index) => {
                  return (
                  
                    <FormControl key={index} fullWidth margin="none">
                  
                        <TextField
                        id={input.id}
                        name={input.name}
                        type={input.type}
                        label={input.label}
                    
                        autoComplete={input.name}
                        autoFocus
                        required={input.required ?? false}
                        onChange={handleInputChange}                      
                        fullWidth                         
                        sx={[                           
                            { mb: { xs: '10px', md: 2 } },
                        ]}
                        />
                     
                    </FormControl>                   
                  );
                })}
                {'history' in caloriesData && caloriesData.history != null  && (
                 <CaloriesHistory history={caloriesData.history}/>
                )}
                   
            </div>
        )
    }

    //calc css
    const caloriesSize = {fontSize:{xs:'min(max(16px, 3.9vw), 23px)',md:'min(max(16px, 1.5vw), 18px)',lg:'min(max(16px, 1.5vw), 25px)',xl:'28px'},fontWeight:'bold'}
    const caloriesTextStyle={
        fontWeight:'bold',
        textAlign:'center',
        fontSize:{xs:'12px',md:'16px'},
    }


    const isMDBigger= useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Stack direction="row" justifyContent={{xs:'center',md:'start',md:'end'}} sx={{ ml:{xs:0,md:'5%'}}}>
            <Stack  direction="row" spacing={{xs:'0',md:'2%',xl:'5%'} } alignItems="center" flexWrap={'wrap'}  sx={{mb:0,justifyContent:'center',marginTop:{xs:'10px',lg:'10px'}, maxWidth:{md:'800px',lg:'900px',xl:'1000px'},flexGrow:1 }}>       
                {/* Profile with circulars */}
                {isMDBigger? (
                <CircularAvatar sliderAchieved={sliderAchieved} goalCalories={goalCalories} userImage={userImage}/>
                ):('')}
                {/* calories data */}
                <Stack className="caloriesCalc" direction="column" flexGrow={1} sx={{ maxWidth:{xs:'98%',sm:'500px',md:'initial'} }}>
                    
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                            <Typography component="h2"   sx={{...caloriesSize,textAlign:['center','left']}}>Calories Remaining</Typography>
                    
                            <IconButton  onClick={handleModalOpen} >
                                <MoreHorizIcon sx={{ fontSize:{xs:'1.7rem',lg:'2rem',xl:'3rem'} }} />
                            </IconButton>
                    </Stack>

                    <Stack direction="row" spacing={'8px'}  sx={{...caloriesTextStyle,px:{xs: 0, md: '5%',lg:'5%'}}} justifyContent={'space-between'}>
                        <Stack direction="column" >
                            <Typography component="h4"  sx={{ ...caloriesSize }}><CountUp duration={1000}>{goalCalories}</CountUp></Typography>
                            <Typography component="p" sx={caloriesTextStyle}>Goal</Typography>
                        </Stack>

                            <Typography component="h4"   sx={{ ...caloriesSize }}>+</Typography>

                        <Stack direction="column">
                                <Typography component="h4"   sx={{ ...caloriesSize }}><CountUp duration={1000}>{foodCalories}</CountUp></Typography>
                                <Typography component="p" sx={caloriesTextStyle}>Food</Typography>
                        </Stack>

                            <Typography component="h4"   sx={{... caloriesSize }}>-</Typography>

                        <Stack direction="column">
                                <Typography component="h4"   sx={{ ...caloriesSize }}><CountUp duration={1000}>{exerciseCalories}</CountUp></Typography>
                                <Typography component="p" sx={caloriesTextStyle}>Exercise</Typography>
                        </Stack>

                            <Typography component="h4"   sx={{ ...caloriesSize }}>=</Typography>
                        
                        <Stack direction="column">
                            <Typography component="h4"   sx={{ ...caloriesSize,fontWeight:'900',color: ifMainColorIsLight(theme) }}><CountUp duration={1000}>{remainingCalories}</CountUp></Typography>
                            <Typography component="p" sx={caloriesTextStyle}>Remaining</Typography>
                        </Stack>
                    </Stack>
                </Stack>

                {/* Modal */}

                <BasicModal data={modalData} />
            </Stack> 
        </Stack>
    );
};

export default UserCalories;