import { useTheme } from '@emotion/react';
import { Avatar, Box, CircularProgress, IconButton, Tooltip, useMediaQuery } from '@mui/material';
import React from 'react';
import { alpha } from '@mui/material';
import { Link, Link as RouterLink } from 'react-router-dom';
import { ifMainColorIsLight, isLight } from '../../helpers/functions';


const CircularAvatar = ({sliderAchieved,goalCalories,userImage}) => {
        const theme =  useTheme();

        //Circular Progress css
             
        const firstCirculatThicness =1.6;
        const secondCirculatThicness = 1;
        const imageOffset = Math.ceil((firstCirculatThicness + secondCirculatThicness) * 4);
    
         // Define the media query conditions
        const isXL = useMediaQuery(theme.breakpoints.up('xl'));
        const isLg= useMediaQuery(theme.breakpoints.up('lg'));
        const isMd= useMediaQuery(theme.breakpoints.up('md'));
        const isSm= useMediaQuery(theme.breakpoints.up('sm'));
    
        // Set the width based on the media query conditions
        const firstCircularWidth = isXL ? '320px' : isLg ?'18.8vw' : isMd ? '17.4vw': isSm ?'20.4vw': '150px';
        const secondCircularWidth = `calc(90% - ${Math.ceil((firstCirculatThicness) * 1)}px)`;
    
        //set colors
        const firstCircularColor = goalCalories > 0 ?  ifMainColorIsLight(theme) : alpha( ifMainColorIsLight(theme),0.4);
        const secondCircularColor = goalCalories > 0 ? alpha( ifMainColorIsLight(theme),0.6) :  alpha( ifMainColorIsLight(theme),0.2);

    return (
        <Box
        className="avatarCircular"
            sx={{ 
                position:'relative',
                display:'block', 
                width:firstCircularWidth,
                height:firstCircularWidth,
                maxWidth:'350px',
                maxHeight:'350px',
                margin:{xs:'auto',md:'initial'}
            }}>
            
            {/* first circular */}
            <CircularProgress variant="determinate" value={sliderAchieved} size={'100%'} sx={{
                color:firstCircularColor,
                '&.MuiCircularProgress-root':{
                // maxWidth:'350px',
                // maxHeight:'350px'
                },
                '.MuiCircularProgress-circle':{
                    strokeWidth:`${firstCirculatThicness}px`
                }
            }}/>

            {/* second circular */}
            <CircularProgress variant="determinate" value={100} size={secondCircularWidth}  sx={{
                color:secondCircularColor,
                position:'absolute',
                left:0,
                right:0,
                bottom:0,
                top:0,
                margin:'auto',   
                '.MuiCircularProgress-circle':{
                    strokeWidth:`${secondCirculatThicness}px`,
                
                }           
            }}
            />

            {/* User image */}
            <Tooltip title="View Profile">
                <IconButton
                component={RouterLink}
                to="/profile"
                variant="plain"
                sx={{
                    position:'absolute',
                    width:`calc(80% - ${imageOffset}px)`,
                    height:`calc(80% - ${imageOffset}px)`,
                    left:0,
                    right:0,
                    bottom:0,
                    top:0,
                    margin:'auto',
                    padding:0,
                    background:'none',
                    '&:hover,&:focus':{
                    background:'none',
                    }
                }}
                >                        
                <Avatar
                    alt="User Image"
                    src={userImage}
                    sx={{ width: '100%', height: '100%'}}
                />
                </IconButton >
            </Tooltip>
        </Box>
    );
};

export default CircularAvatar;