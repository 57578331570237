import React from 'react';

import {
  Button,
  Chip,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import { Watch } from '@mui/icons-material';

const VideoDetailsMobile = ({ vid, videoDialog ,isIntensity}) => {
  const theme = useTheme();
  //css
  const BottomSideClass = {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .meta-left': {
      width: '100%',
      // max-width: '50%',
    },
    '& h3': {
      //font-size: '1.5rem',
      margin: '0',
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& h6': {
      fontSize: '1.5rem',
      margin: '0',
      maxWidth: '45%',
    },
    '& p': {
      margin: '0 0 .25rem',
    },
  };

  const detailsContainerMobileDesign = {
    position: 'relative',
    background: '#FFFFFF',
    borderRradius: '15px',
    padding: {
      xs: '14px 14px 20px 14px',
      lg: '14px 14px 20px 14px',
      xl: '16px 16px 24px 16px',
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& .bottom-side': {
      color: '#000',
    },
    '& .time': {
      color: '#606060',
    },
  };

  const Item = styled('span')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: '#606060',
  }));

  const topBottomSides = {
    position: 'relative',
    zIndex: '1',
    color: '#fff',
  };
  const handlePlayVideo = () => {
    if (videoDialog) {
      videoDialog(true);
    }
  };
  //send recently watched videos to parent in order to refresh video list

  return (
    <Box sx={detailsContainerMobileDesign}>
      <Box
        className="bottom-side"
        sx={(BottomSideClass, topBottomSides)}
      >
        <Typography
          component="h1"
          className="title"
          sx={{
            fontSize: {
              xs: '18px',
              sm: '19px',
              md: '21px',
              xl: '28px',
            },
            margin: '0',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

            fontFamily: theme.typography.roboto,
          }}
        >
          {vid.title}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexShrink: '0',
            flexGrow: '1',
            textAlign: 'right',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <Box sx={{ flexGrow: '1' }}>
            <Box>
              <Stack
                direction="row"
                spacing={'4px'}
                flexWrap={'wrap'}
                sx={{
                  fontSize: {
                    xs: '12px',
                  },
                  alignItems: 'center',

                  fontFamily: theme.typography.roboto,
                }}
              >
            
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'bolder' }}
                  >
                    {vid.duration}
                  </Typography>
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'normal' }}
                  >
                    min
                  </Typography> 
                
                  { isIntensity ? (
                    <>
                    <Typography
                      component="span"
                      variant="p"
                      sx={{ fontWeight: 'normal' }}
                    >
                      |
                    </Typography>
                  
                      <Typography
                        component="span"
                        sx={{
                          fontFamily:theme.typography.roboto,
                        border: '0px',
                        color:'primary.main',
                        fontWeight:'bold',
                        textTransform: 'uppercase',
                        }}
                      >
                        {vid.intensity.name}
                      </Typography>
                      </>
                    ):('')}
                  <Typography
                    component="span"
                    variant="p"
                    sx={{ fontWeight: 'normal' }}
                  >
                    {vid.tags.length > 0 ? '|' : ''}
                  </Typography>
                  <Stack
                    direction="row"
                    spacing={'5px'}
                    sx={{
                      flexWrap: 'wrap',
                      textTransform: 'capitalize',
                    }}
                  >
                    {vid.tags.map((tag, i) => {
                      return (
                        <Item color="inherit" key={i}>
                          {tag}{' '}
                          {i === vid.tags.length - 1 ? '' : ', '}
                        </Item>
                      );
                    })}
                  </Stack>
               
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexShrink: '0',
              flexGrow: '1',
              textAlign: 'right',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handlePlayVideo}
              sx={{
                borderRadius: '24px',
                fontSize: '13px',
                fontWeight: 'bold',
                px: ' 20px',
                letterSpacing: '1px',
              }}
            >
              {vid.watched ? 'Resume' : 'Watch'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default VideoDetailsMobile;
