import React, { useContext, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import AuthContext from '../../context/auth/AuthContext';
import Legal from '../../components/Legal';

import {
  Avatar,
  // Button,
  TextField,
  Link,
  Paper,
  Box,
  Grid,
  Typography,
  FormControl,
  Tooltip,
  alpha,
} from '@mui/material';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { Email, Person, Phone } from '@mui/icons-material';
import { toast } from 'react-toastify';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTheme } from '@emotion/react';

//const identifier = process.env.REACT_APP_IDENTIFIER;
const identifier = window.location.hostname;

export default function Register() {
  const theme = useTheme();
  const { setLoading, apiUrl, loading } = useContext(AuthContext);
  const [state, setState] = useState({
    firstname: '',
    lastname: '',
    email: '',
  });
  const [errors, setErrors] = useState([]);
  const [keyUpErrors, setkeyUpErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const regexPhone = /^(\+)?([ 0-9]){10,16}$/;
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //set the html input fields
  const inputFieldValues = [
    {
      name: 'firstname',
      label: 'First Name',
      id: 'firstname',
      icon: <Person sx={{ mr: 2 }} />,
      required: true,
      maxLength: 524288,
    },
    {
      name: 'lastname',
      label: 'Last Name',
      id: 'lastname',
      icon: <Person sx={{ mr: 2 }} />,
      required: true,
      maxLength: 524288,
    },
    {
      name: 'email',
      label: 'Email',
      id: 'email',
      icon: <Email sx={{ mr: 2 }} />,
      required: true,
      maxLength: 524288,
    },
    {
      name: 'phone',
      label: 'Phone',
      id: 'phone',
      icon: <Phone sx={{ mr: 2 }} />,
      required: true,
      maxLength: 17,
    },
  ];

  const validate = (field, type) => {
    setErrors([]);
    let newErrors = [];

    if (type === 'submit') {
      Object.keys(state).forEach((x) => {
        if (!state[x]) {
          newErrors.push({
            name: x,
            error:
              'The ' +
              x.charAt(0).toUpperCase() +
              x.slice(1) +
              ' field is Required',
          });
        }
      });
    }

    //email format validation
    if (field.email && !regexEmail.test(field.email)) {
      newErrors.push({
        name: 'email',
        error: 'The Email field is invalid (e.g. email@domain.com).',
      });
    }
    //phone format validation
    if (field.phone && !regexPhone.test(field.phone)) {
      if (field.phone.length > 16) {
        newErrors.push({
          name: 'phone',
          error: 'Maximum 16 characters allowed',
        });
      } else if (field.phone.length <= 10) {
        newErrors.push({
          name: 'phone',
          error: 'Minimum 10 characters',
        });
      } else {
        newErrors.push({
          name: 'phone',
          error: 'The Phone field is invalid',
        });
      }
    }

    return newErrors;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errorsHandle = validate(state, 'submit');

    if (errorsHandle.length > 0) {
      setErrors(errorsHandle);
    } else {
      register();
    }
  };

  //remove phone val symbols as user types
  const handleInputReplace = (e) => {
    if (e.target.name === 'phone') {
      let val = e.target.value;

      val = val.replace(
        /[-!$%^&*()/?_|~=`{}[:";<>,.a-zA-Z\s\\'#@]+/g,
        ''
      );
      val = val.replace(/^.\s]/, '+');
      if (val[0] !== '+') {
        val = '+' + val;
      }
      setState({ ...state, [e.target.name]: val });
    }
    return;
  };

  //event on focus out - shows errors in tooltip
  const handleBlurValidation = (e) => {
    const inputName = e.target.name;
    const input = { [inputName]: e.target.value };

    const keyUpErrorsV = validate(input, 'blur');
    // show or hide tooltip depending on errors
    if (keyUpErrorsV.length > 0) {
      setkeyUpErrors(keyUpErrorsV);
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  //update states with input values
  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  let navigate = useNavigate();

  // register a new user
  const register = async () => {
    setLoading(true);
    axios
      .post(`${apiUrl}/register`, {
        email: state.email,
        code: identifier,
        phone: state.phone,
        firstname: state.firstname,
        lastname: state.lastname,
        successUrl:
          window.location.protocol +
          '//' +
          window.location.host +
          '/reset-password',
      })
      .then((res) => {
        // console.log(res);
        toast.success(
          'User created, please check your email address to confirm your account!'
        );
        return navigate('/login', { replace: true });
      })
      .catch((res) => {
        res = res.response;
        if (res.data.error) {
          let errorsArray = [];
          //show ajax errors in toast
          Object.entries(res.data.error).forEach((x) => {
            let fieldName = x[0];
            let fieldValueArray = x[1];
            fieldValueArray.forEach((err) => {
              toast.error(err);
            });
            errorsArray.push({
              name: fieldName,
            });
            setErrors(errorsArray);
          });
        } else {
          toast.error(res.data.message);
        }

        setLoading(false);
      });
    return;
  };

  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: { xs: '16px', md: '35px' },
    lineSeight: '100%',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: { xs: '64px', md: '98px' },
    textTransform: 'none',
  };

  const TextFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: '#fff',
      borderRadius: '50px',
      borderColor: '#fff',
      height: { xs: '50px', md: '70px' },
      pl: { xs: '22px', md: '30px' },
      alignItems: 'flex-end',
      pb: { xs: '7px', md: '15px' },
      '& legend': { display: 'none' },
      '& .MuiOutlinedInput-notchedOutline': {
        top: 0,
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover svg': {
        color: 'primary.main',
      },
    },
    '& .MuiFormLabel-root': {
      top: { xs: '10px', md: '14px' },
      left: { xs: '8px', md: '17px' },
    },
    '& .Mui-focused': {
      borderColor: 'transparent',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent!important',
      },
    },
    '& .MuiInputBase-input': {
      p: '0',
    },
  };

  return (
    <Grid
      container
      sx={{
        flexGrow: '1',
        position: 'relative',
        alignItems: 'stretch',
        height: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: 'flex-end',
      }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={6}
        lg={7}
        sx={{
          position: { xs: 'fixed', sm: 'relative' },
          bottom: { xs: '0px', sm: 'initial' },
          width: { xs: '100%', sm: 'initial' },
          backgroundImage:
            'url(https://source.unsplash.com/random?fitness)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light'
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: { xs: '100%', sm: 'auto' },
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          position: 'relative',
          zIndex: 1,
          background: {
            xs: `linear-gradient(180deg,  ${alpha(
              theme.palette.blackBg.main,
              0
            )} 0%, ${theme.palette.blackBg.main} 47.84%)`,

            sm: theme.palette.common.black,
          },
          boxShadow: 'none',
          display: 'flex',
          alignItems: { xs: 'flex-end', sm: 'center' },
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            flexGrow: '1',
            height: { xs: 'auto', sm: '100%' },
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 4 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              flexGrow: '1',
            }}
          >
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              sx={{
                my: 8,
                mb: { xs: '12px', md: '64px' },
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexGrow: '0',
                maxWidth: '513px',
                width: '100%',
              }}
            >
              <Box sx={{ width: '100%' }}>
                <Typography
                  component="h1"
                  variant="h1"
                  sx={{
                    color: 'white.main',
                    fontSize: { xs: '40px', md: '53px' },
                  }}
                  align="center"
                  mb={{ xs: 1, md: '7vh' }}
                >
                  Register Account
                </Typography>
              </Box>

              <Box sx={{ width: '100%', mt: '2rem' }}>
                {/* generate input fields */}
                {inputFieldValues.map((inputFieldValue, index) => {
                  return (
                    <FormControl key={index} fullWidth margin="none">
                      <Tooltip
                        mb={0}
                        placement="top-end"
                        title={
                          keyUpErrors.find(
                            (x) => x.name === inputFieldValue.name
                          )
                            ? keyUpErrors.find(
                                (x) => x.name === inputFieldValue.name
                              ).error
                            : ''
                        }
                        open={open}
                      >
                        <TextField
                          id={inputFieldValue.id}
                          name={inputFieldValue.name}
                          label={inputFieldValue.label}
                          value={state[inputFieldValue.name]}
                          autoComplete={inputFieldValue.name}
                          autoFocus
                          required={inputFieldValue.required ?? false}
                          onChange={handleChange}
                          onKeyUp={handleInputReplace}
                          onBlur={handleBlurValidation}
                          InputProps={{
                            startAdornment: inputFieldValue.icon,
                          }}
                          inputProps={{
                            maxLength: inputFieldValue.maxLength,
                          }}
                          multiline={
                            inputFieldValue.multiline ?? false
                          }
                          fullWidth
                          rows={inputFieldValue.rows ?? 1}
                          error={
                            errors.find(
                              (x) => x.name === inputFieldValue.name
                            )
                              ? true
                              : false
                          }
                          helperText={
                            errors.find(
                              (x) => x.name === inputFieldValue.name
                            )
                              ? errors.find(
                                  (x) =>
                                    x.name === inputFieldValue.name
                                ).error
                              : null
                          }
                          sx={[
                            TextFieldStyle,
                            { mb: { xs: '10px', md: 2 } },
                          ]}
                        />
                      </Tooltip>
                    </FormControl>
                  );
                })}

                <LoadingButton
                  type="submit"
                  loading={loading}
                  fullWidth
                  variant="contained"
                  sx={[btnStyle, { mb: { xs: '20px', md: 3 } }]}
                >
                  Register
                </LoadingButton>

                <Grid container>
                  <Grid item>
                    <Link
                      component={RouterLink}
                      to="/login"
                      variant="body2"
                      color="white.secondary"
                    >
                      {'Already have an account? Sign In'}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              my: 1,
              mx: 3,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
              justifyContent: 'end',
            }}
          >
            <Legal />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
