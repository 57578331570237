import { useState, useEffect } from 'react';

const easeOutQuad = t => t * ( 2 - t );
const frameDuration = 1000 / 60;

function CountUp( { children, duration = 2000 } )  {
  
	const countTo = parseInt( children, 10 );
	const [ count, setCount ] = useState( 0 );

	useEffect( () => {
    	if(children > 0 ) {
			let frame = 0;
			const totalFrames = Math.round( duration / frameDuration );
			const counter = setInterval( () => {
				frame++;
				const progress = easeOutQuad( frame / totalFrames );
				setCount( countTo * progress );

				if ( frame === totalFrames ) {
					clearInterval( counter );
				}
			}, frameDuration );
		}
	}, [countTo, duration] );
	
  const counted =children > 0  ? Math.floor( count ) : children
 
  return (
    <span>
      {counted}
    </span>
  );
}

export default CountUp;