import React, { useContext } from 'react';
import { Box, Link } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

import AuthContext from '../context/auth/AuthContext';
import PageContext from '../context/page/PageContext';

function Legal() {
  const { user, logout } = useContext(AuthContext);
  const {page} = useContext(PageContext)
  if (user) {
    return false;
  }
  return (
    <Box
      sx={{
        '&>.MuiLink-underlineHover': {
          mr: 1,
          color: '#818181',
          fontSize: '0.89rem',
        },
        '&>.MuiLink-underlineHover:last-of-type': {
          mr: 0,
        },
      }}
    > 

    {!('terms_and_conditions' in page)|| page.terms_and_conditions === null ? ('') :(
      <Link
        component={RouterLink}
        to="/legal/terms"
        underline="hover"
      >
        Terms
      </Link>
      )}
    {!('privacy_policy' in page) || page.privacy_policy === null ? ('') :(
      <Link
        component={RouterLink}
        to="/legal/privacy-policy"
        underline="hover"
      >
        Privacy Policy
      </Link>
       )}
      <Link component={RouterLink} to="/contact" underline="hover">
        Contact
      </Link>
      <Link component={RouterLink} to="/login" underline="hover">
        Sign In
      </Link>
    </Box>
  );
}

export default Legal;
