import React, { useState } from 'react';

import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import { useTheme } from '@emotion/react';

//icons
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';

function Subcategory({
  category,
  index,
  selectedVideo,
  selectedMainCategory,
  openedDrawer,
  subCatExpanded,
  parentVideoCallback,
  parentVideo,
  padding,
  nestedScale,
  selectedAllSubCat,
  colorSelected,
  backgroundSelected,
}) {
  const theme = useTheme();

  const [subCatExpandedChild, setSubCatExpandedChild] =
    useState(subCatExpanded);

  return (
    <>
      {category.subcategories &&
        category.subcategories.length > 0 &&
        category.subcategories.map((subcategory, idx) => (
          <Box
            key={`${subcategory.subcategory_id}-${idx}`}
            className="subcategory"
            sx={{ width: '100%' }}
          >
            <List
              sx={{
                padding: '0px',
              }}
            >
              <ListItem
                sx={{
                  flexDirection: 'column',
                  alignItems: 'stretch',
                  p: 0,
                  flexDirection: 'column',
                  alignItems: openedDrawer ? 'initial' : 'center',
                  justifyContent: 'center',
                  paddingLeft: openedDrawer && padding ? padding : '',
                  borderBottom: `1px solid #343434`,
                  backgroundColor: backgroundSelected(
                    subcategory.subcategory_id,
                    nestedScale,
                    index,
                    subCatExpandedChild,
                    `listsub${subcategory.subcategory_id}-${index}`
                  ),
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 40,

                    padding: '0px 20px',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: openedDrawer ? 'initial' : 'center',
                    color: colorSelected(subcategory.subcategory_id),
                  }}
                  onClick={() => {
                    if (subCatExpandedChild) {
                      let prevListsSub = [...subCatExpandedChild];

                      if (
                        subCatExpandedChild &&
                        !subCatExpandedChild.includes(
                          `listsub${subcategory.subcategory_id}-${index}`
                        )
                      ) {
                        prevListsSub.push(
                          `listsub${subcategory.subcategory_id}-${index}`
                        );
                        let newListsSub = [...new Set(prevListsSub)];
                        setSubCatExpandedChild([
                          ...new Set(newListsSub),
                        ]);
                      } else if (
                        subCatExpandedChild &&
                        subCatExpandedChild.includes(
                          `listsub${subcategory.subcategory_id}-${index}`
                        )
                      ) {
                        const indexList = prevListsSub.indexOf(
                          `listsub${subcategory.subcategory_id}-${index}`
                        );
                        prevListsSub.splice(indexList, 1);
                        setSubCatExpandedChild(prevListsSub);
                      }
                    }
                  }}
                >
                  <Stack direction="row">
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        margin: 'initial',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '18px',
                        color: 'inherit!important',
                        '& svg:not(.arrowEmpty)': {
                          fontSize: '31px',
                          width: '32px',
                          height: '31px',
                          marginLeft: '-8px',
                        },
                        '& svg.arrowEmpty': {
                          marginLeft: '7px',
                          fontSize: '18px',
                          width: '18px',
                          height: '17px',
                        },
                      }}
                    >
                      {nestedScale === 1 && (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            lineHeight: '12px',
                            color: colorSelected(
                              subcategory.subcategory_id
                            ),
                            marginLeft: '2px',
                          }}
                        >
                          {idx + 1}
                        </Typography>
                      )}

                      {subCatExpandedChild &&
                      subCatExpandedChild.includes(
                        `listsub${subcategory.subcategory_id}-${index}`
                      ) ? (
                        nestedScale !== 1 ? (
                          <ArrowDropUpIcon
                            htmlColor={colorSelected(
                              subcategory.subcategory_id
                            )}
                          />
                        ) : (
                          <ArrowForwardIosIcon
                            className="arrowEmpty"
                            htmlColor={colorSelected(
                              subcategory.subcategory_id
                            )}
                            sx={{ transform: 'rotate(-90deg)' }}
                          />
                        )
                      ) : nestedScale !== 1 ? (
                        <ArrowDropDownIcon
                          htmlColor={colorSelected(
                            subcategory.subcategory_id
                          )}
                        />
                      ) : (
                        <ArrowForwardIosIcon
                          className="arrowEmpty"
                          htmlColor={colorSelected(
                            subcategory.subcategory_id
                          )}
                          sx={{ transform: 'rotate(90deg)' }}
                        />
                      )}

                      {nestedScale != 1 && (
                        <Typography
                          sx={{
                            fontSize: '12px',
                            lineHeight: '12px',
                            color: colorSelected(
                              subcategory.subcategory_id
                            ),
                          }}
                        >
                          {idx + 1}
                        </Typography>
                      )}
                    </ListItemIcon>

                    {openedDrawer && (
                      <ListItemText
                        primary={`${subcategory.name}`}
                        sx={{
                          marginLeft: '20px',
                          opacity: openedDrawer ? 1 : 0,
                          '& span.MuiTypography-root': {
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: ' 100%',
                            fontSize: {
                              xs: '14px',
                              sm: '16px',
                            },
                          },
                        }}
                      />
                    )}
                  </Stack>
                </ListItemButton>
              </ListItem>
            </List>
            <Box
              className="secondSubcategory"
              sx={{
                display:
                  // openedDrawer &&
                  subCatExpandedChild &&
                  subCatExpandedChild.includes(
                    `listsub${subcategory.subcategory_id}-${index}`
                  )
                    ? 'block'
                    : 'none',
              }}
            >
              <Subcategory
                category={subcategory}
                index={idx + 1}
                selectedVideo={selectedVideo}
                selectedMainCategory={selectedMainCategory}
                openedDrawer={openedDrawer}
                subCatExpanded={subCatExpandedChild}
                parentVideo={parentVideo}
                padding={`${nestedScale * 10}px `}
                nestedScale={nestedScale + 1}
                selectedAllSubCat={selectedAllSubCat}
                colorSelected={colorSelected}
                backgroundSelected={backgroundSelected}
                parentVideoCallback={parentVideoCallback}
              />
            </Box>
            {subcategory.videos && subcategory.videos.length > 0 && (
              <List
                sx={{
                  p: 0,
                  backgroundColor: theme.palette.drawerBg.main,
                  display:
                    subCatExpandedChild &&
                    subCatExpandedChild.includes(
                      `listsub${subcategory.subcategory_id}-${index}`
                    )
                      ? 'block'
                      : 'none',
                  width: '100%',
                }}
              >
                {subcategory.videos.map((video, vidIndex) => (
                  <ListItem
                    key={vidIndex}
                    className={
                      parentVideo
                        ? video.id === parentVideo.id
                          ? 'playingVideo'
                          : 'listVideo'
                        : ''
                    }
                    sx={{
                      padding: '0px 10px 0px 20px',
                      '&.playingVideo': {
                        background: '#fff',
                        color: '#111111!important',
                      },
                      '&.playingVideo .listVideoTitle': {
                        color: '#111111!important',
                      },

                      '&:hover,&:active': {
                        background: 'rgba(255,255,255,0.95)',
                        color: '#111111!important',
                      },
                      ' &:hover .listVideoTitle ,&:active .listVideoTitle':
                        {
                          color: '#111111!important',
                        },
                      transition: 'all 0.2s ease-in-out',
                    }}
                  >
                    <ListItemButton
                      className="listButton"
                      onClick={() => {
                        parentVideoCallback({
                          currentVideo: video,
                        });
                      }}
                      sx={{
                        minHeight: 70,
                        padding: '10px 0px',
                        justifyContent: openedDrawer
                          ? 'initial'
                          : 'center',

                        color: '#c4c4c4',
                        '&:hover,&:active': {
                          background: 'none',
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          maxWidth: '100%',
                          margin: 'initial',
                          mr: openedDrawer ? 3 : 'auto',
                          justifyContent: 'center',
                          alignItems: 'center',
                          '& svg': {
                            fontSize: '15px',
                            width: '16px',
                            height: '15px',

                            marginRight: '7px',
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          sx={{
                            alignItems: 'center',
                          }}
                        >
                          <ChangeHistoryIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{
                              transform: 'rotate(90deg)',

                              marginLeft: '3px',
                            }}
                          />

                          <Typography
                            className="listVideoTitle"
                            sx={{
                              fontSize: '12px',
                              lineHeight: '12px',
                              color: '#fff',
                            }}
                          >
                            {vidIndex + 1}
                          </Typography>

                          <Stack
                            direction="row"
                            sx={{
                              flexGrow: 0,
                            }}
                          >
                            <Stack
                              direction="column"
                              sx={{
                                marginLeft: '20px',
                                fontFamily: theme.typography.roboto,
                              }}
                            >
                              {' '}
                              <Box
                                sx={{
                                  maxWidth: '100%',
                                  display: 'grid',
                                }}
                              >
                                <Typography
                                  className="listVideoTitle"
                                  sx={{
                                    color: '#fff',
                                    display: openedDrawer
                                      ? 'block'
                                      : 'none',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    maxWidth: ' 100%',
                                    fontSize: {
                                      xs: '14px',
                                      sm: '16px',
                                    },
                                  }}
                                >
                                  {video.title}
                                </Typography>
                              </Box>
                              {openedDrawer ? (
                                <>
                                <Box
                                  component="div"
                                  sx={{
                                    color: '#868686',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    fontSize: '14px',
                                    marginTop: '5px',
                                    flexWrap:'wrap',
                                    fontFamily:
                                      theme.typography.roboto,
                                  }}
                                >
                                  {video.kcal === null || !video.kcal ? ('') : (
                                    <Box sx={{ display:'flex',flexDirection:'row',alignItems:'center',  marginRight:{xs:'3%',md:'6%'} }}>
                                      <AccessTimeIcon
                                        htmlColor={'#868686'}
                                        sx={{
                                          width: '20px!important',
                                          height: '21px!important',
                                        }}
                                      />
                                      <Typography
                                        component="span"
                                        sx={{
                                          marginRight: '2px',
                                          color: 'inherit',
                                          fontWeight: 'bolder',
                                        }}
                                      >
                                        {video.duration}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        sx={{
                                          color: 'inherit',
                                        
                                        }}
                                      >
                                        min
                                      </Typography>
                                  </Box>
                                  )}
                                 {video.kcal === null || !video.kcal ? ('') : (
                                   <Box sx={{ display:'flex',flexDirection:'row',alignItems:'center' }}>
                                      <FitnessCenterIcon  htmlColor={'#868686'}
                                        sx={{
                                          width: '18px!important',
                                          height: '19px!important',
                                        }}/>
                                      <Typography
                                        component="span"
                                        sx={{
                                          marginRight: '2px',
                                          color: 'inherit',
                                          fontWeight: 'bolder',
                                        }}
                                      >
                                        {video.kcal}
                                      </Typography>
                                      <Typography
                                        component="span"
                                        sx={{
                                          color: 'inherit',
                                          marginRight:'5px'
                                        }}
                                      >
                                        kcal
                                      </Typography>
                                  </Box>
                                 )}
                                </Box>
                                </>
                              ) : (
                                ''
                              )}
                            </Stack>
                          </Stack>
                        </Stack>
                      </ListItemIcon>
                    </ListItemButton>
                    {openedDrawer ? (
                      <Stack direction="row" spacing={'0px'}>
                        <IconButton
                          onClick={() => {
                            parentVideoCallback({
                              savedVideo: {
                                video_id: video.id,
                                video_title: video.title,
                              },
                            });
                          }}
                          // onClick={() => {
                          //   setSavedVideo({
                          //     video_id: video.id,
                          //     video_title: video.title,
                          //   });
                          // }}
                        >
                          <AddIcon
                            htmlColor={theme.palette.primary.main}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            parentVideoCallback({
                              scheduledVideo: {
                                video_id: video.id,
                                video_title: video.title,
                              },
                            });
                          }}
                          // onClick={() => {
                          //   setScheduleVideo({
                          //     video_id: video.id,
                          //     video_title: video.title,
                          //   });
                          // }}
                        >
                          <CalendarMonthIcon
                            htmlColor={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Stack>
                    ) : (
                      ''
                    )}
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        ))}
    </>
  );
}

export default Subcategory;
