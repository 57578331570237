import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import AuthContext from '../../context/auth/AuthContext';
import {
  Grid,
  Box,
  CircularProgress,
  Container,
  Typography,
  Button,
  Stack,
  Chip,
  IconButton,
  Tooltip,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { alpha } from '@mui/material';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import axios from 'axios';
import CategoryItem from '../../components/CategoryItem';
import GreetingsHeader from '../../components/GreetingsHeader';
import VideoItem from '../../components/videoitem/VideoItem';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmDialog from '../../components/ConfirmDialog';
import { ifMainColorIsLight, isLight } from '../../helpers/functions';
//const identifier = process.env.REACT_APP_IDENTIFIER;
const identifier = window.location.hostname;

// Layout for Search - future design
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.common.white,
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.5),
  },
  marginLeft: 0,
  width: '100%',
  maxWidth: '490px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  marginTop: '1rem',
  borderRadius: '20px',
  padding: '3px 20px',
  display: 'flex',
  flexDirection: 'row',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '& .MuiSvgIcon-root ': {
    color: 'rgb(228, 64, 135)',
  },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  flexGrow: '1',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    filter: 'hue-rotate(101deg)',
    fontSize: { xs: '0.8rem' },
  },
}));

//for Scheduled Videos

let start = moment().subtract(2, 'days');
let end = moment().add(7, 'days');

var daysArray = [];
var day = start;

while (day <= end) {
  daysArray.push({
    day: day.format('D'),
    date: day,
    name: day.format('dddd'),
    full: day.format('dddd, MMMM Do YYYY'),
  });
  day = day.clone().add(1, 'd');
}

// Categories
function Categories() {
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [banner, setBanner] = useState([]);
  const [bannerSrc, setBannerSrc] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  //loaders
  const [loading, setLoading] = useState(true); //categories
  const [loadingFeatured, setLoadingFeatured] = useState(true);
  const [loadingWatched, setLoadingWatched] = useState(true);
  const [loadingSaved, setLoadingSaved] = useState(true);
  const [loadingScheduled, setLoadingScheduled] = useState(true);

  const [filters, setFilters] = useState([]);
  const [showedFilters, setshowedFilters] = useState(false);
  const [allTags, setAllTags] = useState([]);
  const [recentWatchedVideos, setRecentWatchedVideos] =
    useState(false);
  const [featuredSessionVideos, setFeaturedSessionVideos] =
    useState(false);
  const [savedVideos, setSavedVideos] = useState([]);
  const [deleteSavedVideo, setDeleteSavedVideo] = useState(false);
  const [dialogConfirmData, setDialogConfirmData] = useState([]);

  const [childVideo, setchildVideo] = useState([]);
  const theme = useTheme();
  const filtersShown = showedFilters ? 'fl-shown' : 'fl-hidden';
  const authContext = useContext(AuthContext);
  const { user, apiUrl, token, updateUser, whoAmI } = authContext;

  //scheduled Videos
  const [activeDay, setActiveDay] = useState(moment().format('D'));
  const [scheduledVideos, setScheduledVideos] = useState(false);
  const [scheduledVideosToShow, setScheduledVideosToShow] = useState({
    time: 'today',
    date: '',
    videos: [],
  });

  const handleFiltersVisibility = () => {
    showedFilters ? setshowedFilters(false) : setshowedFilters(true);
  };

  const getCategories = async () => {
    return await axios.get(
      `${apiUrl}/v2/categories?reseller_domain=${identifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getBanner = async () => {
    return await axios.get(
      `${apiUrl}/v2/reseller-banner?reseller_domain=${identifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getSavedVideosId = async () => {
    return await axios.get(`${apiUrl}/user/getWishedVideoList`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  //load Videos on page load
  useLayoutEffect(() => {
    getSavedVideosId().then((res) => {
      const data = res?.data?.data;
      const videos = data.reverse(); //sort to descending
      setSavedVideos(videos);
      setLoadingSaved(false);
    });
  }, []);

  useEffect(() => {
    getCategories().then((res) => {
      const cat = res?.data?.data;
      setCategories(cat);
      setAllCategories(cat);

      // if (!searchTerm ) {
      //   //on page load
      //   setCategories(cat);
      //   setAllCategories(cat);
      // } else {
      //   //filter categories pbject based on search term
      //   const results = cat.filter((catItem) =>
      //     catItem.title
      //       .toLowerCase()
      //       .includes(searchTerm.toLowerCase())
      //   );

      //   if (Object.keys(results).length === 0) {
      //     //if the obj is empty, show all cats
      //     setCategories(cat);
      //     setAllCategories(cat);
      //   } else {
      //     setCategories(results);
      //   }
      //}

      setLoading(false);
    });
 
      getBanner().then((res) => {
        const bannerRes = res?.data?.data;
        if(bannerRes && bannerRes.active === true && Object.keys(bannerRes).length > 0 )
         setBanner(bannerRes);
      });
    
    //eslint-disable-next-line
  }, [searchTerm]);

 //detect MD breakPoint
 const breakPointMD = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
  
    if(banner && banner.banner_desktop && banner.banner_link) {
      setBannerSrc(banner.banner_mobile && breakPointMD ? banner.banner_mobile: banner.banner_desktop);
    } 
   
  }, [banner,breakPointMD])
  
  //useEffect on allcategories
  useEffect(() => {
    //get recently watched stored videos from localstorage
    let recentWatched = localStorage.getItem('videosWatched')
      ? JSON.parse(localStorage.getItem('videosWatched'))
      : false;

    //if it's still Loading categories, return
    if (loading) return;

    //if loaded categories and there are no recentwatched videos, stop loader for recent watched and featured session
    if (!recentWatched) {
      setLoadingWatched(false);
      setLoadingFeatured(false);
      return;
    }

    //filter videos- get the videos from categories array
    let videosFiltered = getVideoByStoredId(recentWatched);

    //set recent watched videos
    setRecentWatchedVideos(videosFiltered);
    setchildVideo(recentWatched);
  }, [allCategories]);

  //get all tags from categories array
  useEffect(() => {
    let uniqueTagsArray = [];
    categories.map((category, i) => {
      if (category && category.videos) {
        category.videos.forEach((x) => {
          x.tags.forEach((y) => {
            if (uniqueTagsArray.indexOf(y) === -1) {
              uniqueTagsArray.push(y);
            }
          });
        });
      }
    });
    setAllTags(uniqueTagsArray);
  }, [categories]);

  //clear Workouts Tag Filters
  const clearFilters = () => {
    setFilters([]);
    setCategories(allCategories);
  };

  //delete Workouts each Tag Filter
  const handleTagDelete = (event) => {
    const arr = filters.filter((item) => item !== event.target.value);
    setFilters(arr);

    setCategories(
      arr.length > 0
        ? filterCategories(arr, allCategories)
        : allCategories
    );
  };

  //filter categories by tag
  const filterCategories = (filters, categories) => {
    if (filters.length > 0) {
      let filtered;
      filters.forEach((el) => {
        filtered = categories.filter((r) =>
          r.videos.some((i) => i.tags.some((t) => t === el))
        );
      });

      return filtered;
    }
  };

  //get filter data on click
  const handleFilter = (event) => {
    const {
      target: { value },
    } = event;
    let prevFilters = [...filters];
    prevFilters.push(value);
    let newFilters = [...new Set(prevFilters)];
    setFilters([...new Set(prevFilters)]);

    const filteredCategories = filterCategories(
      newFilters,
      categories
    );

    setCategories(filteredCategories);
  };

  useEffect(() => {
    if (recentWatchedVideos) {
      setFeaturedSessionVideos(filterFeaturedSession());

      //if the recentWatchedVideos is other than false ([] or has items), stop featured loader
      setLoadingFeatured(false);
    }
  }, [recentWatchedVideos]);

  //loop trough category videos and get the ones that were watched
  const getVideoByStoredId = (storedVideos) => {
    storedVideos.sort((x, y) => y.timestamp - x.timestamp); //sort to descending

    if (storedVideos.length > 0) {
      let allFiltered = [];

      storedVideos.forEach((el) => {
        //loop trough stored videos
        //search trough all categories
        allCategories.forEach((category) => {
          //search trough all videos
          category.videos.forEach((video) => {
            if (video.id === el.id) {
              //if the video id is same with the stored video id

              allFiltered.push(video);
            }
          });
        });
      });

      return allFiltered;
    } else return [];
  };

  //get the watched videos from child(videoItem)
  const handleCallbackVideoData = async (childData) => {
    if (childData ) {
   
      if('watchedVideos' in childData){
        setRecentWatchedVideos(getVideoByStoredId(childData.watchedVideos));
        setchildVideo(childData.watchedVideos);
      }
    }
  };

  // FeaturedVideos ___________

  //filter the category videos by watched video tag , and return featured
  const filterFeaturedSession = () => {
    //set featured based on recentWatched videotags

    let recentVideosTags = [];
    //loop trough recently watched videos and get the tags of watched videos
    recentWatchedVideos.forEach((video) => {
      video.tags.forEach((tags) => {
        //if there are tags
        if (recentVideosTags.indexOf(tags) === -1) {
          recentVideosTags.push(tags);
        }
      });
    });

    //get the recently watched video id's
    let recentVideoIds = [];
    recentWatchedVideos.forEach((video) => {
      recentVideoIds.push(video.id);
    });

    //get the featured videos from categories, by vide tags, exepting the watched videos
    let getFeaturedCategories = getFeaturedVideosByTags(
      recentVideosTags,
      recentVideoIds
    );

    return getFeaturedCategories;
  };

  //search trough categories to get featured videos by recently watched video tasg, excepting the videos that are in recently watched
  const getFeaturedVideosByTags = (tagsIn, idsOut) => {
    if (tagsIn.length > 0) {
      let allFiltered = [];

      tagsIn.forEach((el) => {
        categories.map((category) => {
          category.videos.forEach((video) => {
            let videoIn =
              idsOut && idsOut.length > 0
                ? idsOut.find((item) => item === video.id)
                : false; // find if this video id is in the watched video id's
            //loop trough tags in category videos
            video.tags.forEach((tag) => {
              //if the tag is same as the ones from recently watched  but the video id is not the same as the watched
              if (tag === el && !videoIn) {
                allFiltered.push(video);
              }
            });
          });
        });
      });

      return allFiltered;
    }
  };

  //fn to get videos based on hardcoded tags
  const hardcodedFilters = (myTags) => {
    const featuredHardcodedFilter = filterCategories(
      myTags,
      allCategories
    );

    let featuredHardcodedVideos = [];
    let counter = 0;

    featuredHardcodedFilter.map((item, i) => {
      if (item && item.videos) {
        item.videos.forEach((vid) => {
          counter++;
          if (counter < 5) featuredHardcodedVideos.push(vid);
        });
      }
    });

    return featuredHardcodedVideos;
  };

  //Scheduled _________

  useEffect(() => {
    getVideos(activeDay);
  }, [activeDay]);

  const getVideos = (day) => {
    let formatDay = moment(
      daysArray.find((x) => x.day === day).date
    ).format('YYYY-MM-DD');
    axios
      .get(
        `${apiUrl}/user/getScheduledVideos?date=${formatDay}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((x) => {
        let videos = x.data.data;
        setScheduledVideos({ ...videos });
        if (
          x.data.data.selection.length === 0 ||
          x.data.data.upcoming.length === 0
        ) {
          setLoadingScheduled(false);
        }
      });
  };

  //get scheduled videos for Today
  const extractTodayVideos = () => {
    let videosArray = [];

    scheduledVideos.selection.forEach((x) => {
      let toArray = Object.entries(x.videos);
      toArray.forEach((vidArr, x) => {
        if (vidArr && vidArr[1]) {
          videosArray = [...videosArray, ...vidArr[1]];
        }
      });
    });

    return videosArray;
  };

  //filter scheduled videos
  useEffect(() => {
    if (!scheduledVideos) {
      return;
    }
    //if there are videos scheduled today
    if (scheduledVideos) {
      if (scheduledVideos.selection.length > 0) {
        setScheduledVideosToShow({
          time: '',
          date: daysArray.find((x) => x.day === activeDay).full,
          videos: extractTodayVideos(),
        });
        setLoadingScheduled(false);
      }
      //if there are not videos scheduled today, but there are scheduled in the future
      else if (scheduledVideos.upcoming.length > 0) {
        setScheduledVideosToShow(extractUpcomingVideos());
        setLoadingScheduled(false);
      }
      //setLoadingScheduled(false);
    }
  }, [scheduledVideos]);

  //get upcoming Scheduled Videos
  const extractUpcomingVideos = () => {
    let videosArray = [];

    //sort videos asc by date
    let sortedVideos = scheduledVideos.upcoming.sort(
      (a, b) =>
        new Date(...a.date.split('/').reverse()) -
        new Date(...b.date.split('/').reverse())
    );

    //convert to array
    let toArray = Object.entries(sortedVideos[0].videos);
    //loop and push to videosArray
    toArray.forEach((vidArr, x) => {
      if (vidArr && vidArr[1]) {
        videosArray.videos = [...videosArray, ...vidArr[1]];
      }
    });
    //set time and date to use in view
    videosArray.time = 'Upcoming: ';
    videosArray.date = moment(
      sortedVideos[0].date,
      'DD-MM-YYYY'
    ).format('dddd, MMMM Do YYYY');

    return videosArray;
  };

  //progressbar
  const handleProgressBar = (vidId) => {
    let obj = findStoredVideo(vidId);
    let progress = 0;
    if (!obj) {
      return 0;
    }
    if (obj.progress > 0) {
      progress = (obj.progress * 100) / obj.progressEnd;
    }
    if (obj.progress === obj.progressEnd) {
      progress = 100;
    }

    return progress;
  };
  //search the id in stored videos
  const findStoredVideo = (videoID) => {
    //get all watched videos
    const allVideos =
      childVideo.length > 0
        ? childVideo
        : JSON.parse(localStorage.getItem('videosWatched'));

    return allVideos.find((item) => item.id === videoID);
  };
  //if there is a video_id in state, show dialog
  useEffect(() => {
    if (deleteSavedVideo && deleteSavedVideo.video_id) {
      //after dialog confirmation => onDialogConfirm
      setDialogConfirmData({
        message: (
          <Typography>
            Are you sure you want to delete
            <strong> {deleteSavedVideo.video_title}</strong>?
          </Typography>
        ),
        onSubmit: onDialogConfirm,
        onCancel: OnDialogCancel,
      });
    }
  }, [deleteSavedVideo]);

  //reset schedule dialog
  const OnDialogCancel = () => {
    setDialogConfirmData([]);
    setDeleteSavedVideo(false);
  };

  const onDialogConfirm = () => {
    //delete saved video API
    axios
      .delete(`${apiUrl}/user/wishedVideoList`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: { video_id: deleteSavedVideo.video_id },
      })
      .then((res) => {
        toast.success(`Video Removed!`);
        //reset dialog in order to close
        OnDialogCancel();

        //reset the saved videos and load them again to refresh list
        getSavedVideosId().then((res) => {
          const data = res?.data?.data;
          const videos = data.reverse(); //sort to descending
          setSavedVideos(videos);
        });
      })
      .catch((err) => {
        console.error(err.message);
        toast.error(err.message);
      });
  };

  //css
  const chipStyle = {
    padding: { xs: '5.5px 15px', sm: '5.5px 40px' },
    fontSize: { xs: '12px', sm: '15px' },
    margin: '0px 7.5px 15px 7.5px',
    background: '#C4C4C4',
    textTransform: 'capitalize',
    fontWeight: 700,
    height: '32px',
    borderRadius: '16px',
    whiteSpace: 'no-wrap',
    width: { xs: 'calc(50% - 15px)', sm: 'auto' },
    whiteSpace: 'nowrap',
  };
  const filtersContainerStyle = {
    maxWidth: '100%',
    display: ' flex',
    alignItems: 'start',
    '& .fl-shown': {
      height: '100%',
      '& .showFLButton': {
        display: 'none',
      },
      '& .closeFilters': {
        display: 'inline-flex',
      },
    },
    '& .fl-hidden': {
      height: '40px',
      '&  .showFLButton': {
        display: 'inline-flex',
      },
      '& .closeFilters': {
        display: 'none',
      },
    },
  };

  const filtersOnStyle = {
    padding: '5.5px 15px 5.5px 15px',
    width: '100%',
    fontSize: { xs: '12px', sm: '15px' },
    backgroundColor: 'primary.main',
    color: isLight(theme.palette.primary.main)
      ? isLight(theme.palette.primary.contrastText)
        ? 'common.black'
        : 'primary.contrastText'
      : 'primary.contrastText',
    textTransform: 'capitalize',
    fontWeight: 400,
    height: '32px',
    borderRadius: '16px',
    whiteSpace: 'no-wrap',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: 'inherit',
      background: '#eaeaea',
    },
    '&:hover svg': {
      fill: 'inherit',
    },
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        px: { xs: '27px', md: '50px' },
      }}
    >
      {/* heroImage */}
      <Box
        sx={{
          color: '#181818',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          justifyContent: { md: 'center' },
          fontSize: '.9em',
          position: 'relative',
          px: 0,
          pt: 3,
          pb: '30px',
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            position: 'relative',
            px: '0!important',
          }}
        >
          <GreetingsHeader />

          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              type="search"
              placeholder="Search for workouts"
              inputProps={{ 'aria-label': 'Search for workouts' }}
              value={searchTerm}
              onChange={handleChange}
            />
          </Search> */}
        </Container>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', mb: '2em' }}>
            <Typography
              component="p"
              variant="h1"
              sx={{ fontSize: '1.8em', letterSpacing: ' 0.15px' }}
            >
              Workout Videos
            </Typography>
          </Box>
        </Grid>
      </Grid>
      {featuredSessionVideos && featuredSessionVideos.length > 0 && (
        <Box sx={{ marginBottom: { xs: '40px', md: '77px' } }}>
          <Typography
            component="p"
            variant="h1"
            sx={{
              fontSize: '24px',
              letterSpacing: ' 0.15px',
              color: 'common.secondaryHeadline',
            }}
          >
            Featured Session
          </Typography>
          <Paper
            sx={{
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              overflowX: 'auto',
              pb: '10px',
              scrollBehavior: 'smooth',
              background: 'transparent',
            }}
            elevation={0}
          >
            <Grid
              container
              spacing={3}
              mt={0}
              sx={{ flexWrap: 'nowrap' }}
            >
              {!featuredSessionVideos ||
              featuredSessionVideos.length === 0 ? (
                <>
                  {!loadingFeatured ? (
                    <Grid item>No Featured Sessions</Grid>
                  ) : (
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {featuredSessionVideos.map((vid, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        lg={5}
                        sx={{
                          flex: '1 0 100%!important',
                          maxWidth: { xl: '593px!important' },
                        }}
                      >
                        <VideoItem
                          vid={vid}
                          categoriesCallback={handleCallbackVideoData}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Paper>
        </Box>
      )}
      {scheduledVideosToShow &&
        scheduledVideosToShow.videos.length > 0 && (
          <Box sx={{ marginBottom: { xs: '40px', md: '77px' } }}>
            <Typography
              component="p"
              variant="h1"
              sx={{
                fontSize: '24px',
                letterSpacing: ' 0.15px',
                color: 'common.secondaryHeadline',
              }}
            >
              Scheduled Videos
              {scheduledVideosToShow &&
              scheduledVideosToShow.videos.length > 0 ? (
                <>
                  {' '}
                  -
                  <Typography
                    component="span"
                    variant="p"
                    sx={{
                      display: 'inline',
                      fontSize: 'inherit',
                      color: ifMainColorIsLight(theme),
                    }}
                  >
                    {' '}
                    {scheduledVideosToShow.time}
                  </Typography>{' '}
                  {scheduledVideosToShow.date}
                </>
              ) : (
                ''
              )}
            </Typography>
            <Paper
              sx={{
                maxWidth: '100%',
                width: '100%',
                display: 'flex',
                overflowX: 'auto',
                pb: '10px',
                scrollBehavior: 'smooth',
                background: 'transparent',
              }}
              elevation={0}
            >
              <Grid
                container
                spacing={3}
                mt={0}
                sx={{ flexWrap: 'nowrap' }}
              >
                {scheduledVideosToShow.videos.length === 0 ? (
                  <>
                    {!loadingScheduled ? (
                      <Grid item>No Scheduled Videos</Grid>
                    ) : (
                      <Grid item xs={12}>
                        <CircularProgress />
                      </Grid>
                    )}
                  </>
                ) : (
                  <>
                    {scheduledVideosToShow.videos.map((vid, i) => {
                      return (
                        <Grid
                          key={i}
                          item
                          xs={12}
                          sm={8}
                          md={7}
                          lg={5}
                          sx={{
                            flex: '1 0 100%!important',
                            maxWidth: { xl: '593px!important' },
                          }}
                        >
                          <VideoItem
                            vid={vid}
                            categoriesCallback={handleCallbackVideoData}
                          />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </Grid>
            </Paper>
          </Box>
        )}

      {recentWatchedVideos && recentWatchedVideos.length > 0 && (
        <Box sx={{ marginBottom: { xs: '40px', md: '77px' } }}>
          <Typography
            component="p"
            variant="h1"
            sx={{
              fontSize: '24px',
              letterSpacing: ' 0.15px',
              color: 'common.secondaryHeadline',
            }}
          >
            Recently Watched Video
          </Typography>
          <Paper
            sx={{
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              overflowX: 'auto',
              pb: '10px',
              scrollBehavior: 'smooth',
              background: 'transparent',
            }}
            elevation={0}
          >
            <Grid
              container
              spacing={3}
              mt={0}
              sx={{ flexWrap: 'nowrap' }}
            >
              {!recentWatchedVideos ||
              recentWatchedVideos.length === 0 ? (
                <>
                  {!loadingWatched ? (
                    <Grid item>No Recently Watched Videos</Grid>
                  ) : (
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {recentWatchedVideos.map((vid, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        sm={8}
                        md={7}
                        lg={5}
                        sx={{
                          flex: '1 0 100%!important',
                          maxWidth: { xl: '593px!important' },
                        }}
                      >
                        <VideoItem
                          vid={vid}
                          isRecent={true}
                          categoriesCallback={handleCallbackVideoData}
                          progress={handleProgressBar(vid.id)}
                        />
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Paper>
        </Box>
      )}
      {savedVideos && savedVideos.length > 0 && (
        <Box sx={{ marginBottom: { xs: '40px', md: '77px' } }}>
          <Typography
            component="p"
            variant="h1"
            sx={{
              fontSize: '24px',
              letterSpacing: ' 0.15px',
              color: 'common.secondaryHeadline',
            }}
          >
            Saved Videos
          </Typography>
          <Paper
            sx={{
              maxWidth: '100%',
              width: '100%',
              display: 'flex',
              overflowX: 'auto',
              pb: '10px',
              scrollBehavior: 'smooth',
              background: 'transparent',
            }}
            elevation={0}
          >
            <Grid
              container
              spacing={3}
              mt={0}
              sx={{ flexWrap: 'nowrap' }}
            >
              {savedVideos.length === 0 ? (
                <>
                  {!loadingSaved ? (
                    <Grid item>No Saved Videos</Grid>
                  ) : (
                    <Grid item xs={12}>
                      <CircularProgress />
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  {savedVideos.map((vid, i) => {
                    return (
                      <Grid
                        key={i}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        sx={{
                          flex: '1 0 100%!important',
                          maxWidth: { xl: '361px!important' },
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            height: '100%',
                            position: 'relative',
                          }}
                        >
                          <VideoItem
                            vid={vid}
                            categoriesCallback={handleCallbackVideoData}
                          />
                          <Box
                            sx={{
                              background: alpha(
                                theme.palette.common.white,
                                0.66
                              ),
                              borderRadius: '50%',
                              display: 'inline-block',
                              position: 'absolute',
                              top: '14px',
                              right: '14px',
                              zIndex: '2',
                            }}
                          >
                            <Tooltip
                              title="Remove Video"
                              sx={{
                                backgroundColor: alpha(
                                  theme.palette.warning.main,
                                  0.1
                                ),
                                color: alpha(
                                  theme.palette.warning.main,
                                  0.7
                                ),
                                // boxShadow: '0px 0px 6px rgba(0,0,0, 0.1)',
                                '& svg': {
                                  color: alpha(
                                    theme.palette.warning.main,
                                    0.7
                                  ),
                                  fill: alpha(
                                    theme.palette.warning.main,
                                    0.7
                                  ),
                                  width: '20px',
                                  height: '20px',
                                },
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                  color: theme.palette.warning.main,
                                },
                                '&:hover svg': {
                                  color: theme.palette.warning.main,
                                  fill: theme.palette.warning.main,
                                },
                              }}
                            >
                              <IconButton
                                aria-label="remove video"
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                  setDeleteSavedVideo({
                                    video_id: vid.id,
                                    video_title: vid.title,
                                  });
                                }}
                              >
                                <CloseIcon
                                  htmlColor={
                                    theme.palette.warning.main
                                  }
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </>
              )}
            </Grid>
          </Paper>
        </Box>
      )}
      <Box sx={{}}>
        <Grid
          container
          sx={{
            flexWrap: { xs: 'wrap', md: 'nowrap' },
          }}
        >
          <Grid
            item
            sx={{
              maxWidth: { xs: '100%', md: '153px' },
              flex: '1 1 100%',
              px: '0!important',
              marginRight: { xs: '0px', sm: '72px' },
            }}
          >
            <Typography
              component="p"
              variant="h1"
              sx={{
                fontSize: '24px',
                letterSpacing: ' 0.15px',
                mb: '29px',
              }}
            >
              Selected Filters
            </Typography>

            <Stack
              direction="column"
              spacing={'15px'}
              sx={{ mb: { xs: '40px', md: '0px' } }}
            >
              {filters.length > 0 ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{
                      borderRadius: '20px',
                      color: ifMainColorIsLight(theme),
                      borderColor: ifMainColorIsLight(theme),
                    }}
                    onClick={clearFilters}
                  >
                    Clear filters
                  </Button>
                  {filters.map((x, i) => {
                    return (
                      <IconButton
                        variant="Chip"
                        sx={filtersOnStyle}
                        key={i}
                        value={x}
                        onClick={handleTagDelete}
                      >
                        {x}
                      </IconButton>
                    );
                  })}
                </>
              ) : (
                <>
                  {!loading ? (
                    'There are no filters'
                  ) : (
                    <CircularProgress />
                  )}
                </>
              )}
            </Stack>
          </Grid>
          <Grid item sx={{ flexGrow: '1' }}>
            {/* Tag Filters */}
            <Box>
              <Typography
                component="p"
                variant="h1"
                sx={{ fontSize: '24px', letterSpacing: ' 0.15px' }}
                mb={'31px'}
              >
                Filters
              </Typography>

              <Box sx={filtersContainerStyle}>
                <Box
                  sx={{
                    maxWidth: '100%',
                    display: ' flex',
                    alignItems: 'start',
                    position: 'relative',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                  className={filtersShown}
                >
                  <Box
                    sx={{
                      mx: '-7.5px',
                      display: 'flex',
                      flexWrap: 'wrap',
                      overflow: 'hidden',
                    }}
                    className={[filtersShown, 'tagsContainer']}
                    flexGrow={1}
                  >
                    {allTags.length > 0 ? (
                      <>
                        {allTags.map((x, i) => {
                          return (
                            <Button
                              variant="Chip"
                              sx={chipStyle}
                              key={i}
                              onClick={handleFilter}
                              value={x}
                              className="tag-fl"
                            >
                              {x}
                            </Button>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {!loading ? (
                          'There are no filters'
                        ) : (
                          <CircularProgress />
                        )}
                      </>
                    )}
                  </Box>
                  {allTags.length > 0 || !loading ? (
                    <Box>
                      <Chip
                        label="All Filters ->"
                        variant="contained"
                        component="button"
                        sx={[
                          chipStyle,
                          {
                            width: 'auto!important',
                            '& span': {
                              padding: '0px',
                            },
                          },
                        ]}
                        onClick={handleFiltersVisibility}
                        className="showFLButton"
                      />
                      <IconButton
                        aria-label="closeFilters"
                        className="closeFilters"
                        onClick={handleFiltersVisibility}
                        sx={{
                          position: 'absolute',
                          top: '-40px',
                          right: '0px',
                          cursor: 'pointer',
                        }}
                      >
                        <KeyboardArrowUpIcon color="primary" />
                      </IconButton>
                    </Box>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
            {/* end of  Tag  Filters */}
            
            <Box>
              <Box>
                <Typography
                  component="p"
                  variant="h1"
                  sx={{
                    fontSize: '24px',
                    letterSpacing: ' 0.15px',
                    color: 'common.secondaryHeadline',
                  }}
                  mt={'71px'}
                  mb={'31px'}
                >
                  Workouts
                </Typography>
              </Box>
              <Grid
                container
                spacing={{ xs: 2, sm: 3, md: 5 }}
                sx={{ maxWidth: { lg: '1242px', xl: 'calc(100% + 40px)' } }}
              >
                {categories.length > 0 ? (
                  <>
                    {categories.map((x, i) => {
                      return (
                        <Grid item key={i} xs={6} md={6} lg={3}>
                          <CategoryItem data={x} key={i} i={i} />
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {!loading ? (
                      'There are no categories'
                    ) : (
                      <CircularProgress />
                    )}
                  </>
                )}
              </Grid>
            </Box>
     
            {banner && bannerSrc && (
             
            <Box fullWidth sx={{ pt:2,display:'flex',justifyContent:'center'}}>
                <Button       
                variant="text" disableElevation disableRipple
                href={banner.banner_link}
                target="_blank"
                sx={{
                  background:'transparent',
                  width: '54%',
                  maxHeight: {xs:'80vh',md:'22vw'},
                  minWidth: {xs:'100%',md:'444px'},
                  p:0,
                  '&:hover':{
                    background:'transparent'
                  }
                }}
              >
              
                  <img
                  alt=""
                  src={bannerSrc}
                  variant="square"
                  style={{
                    width: '100%',
                    display: 'block',
                    height:' 100%',
                    objectFit: 'contain',
                    objectPosition: 'top center',
                  }}
                    />
              </Button>
                
            </Box>
             )}
          </Grid>
        </Grid>
      </Box>
      <ConfirmDialog dialogData={dialogConfirmData} />
    </Container>
  );
}

export default Categories;
