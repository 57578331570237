const authReducer = (state, action) => {
	switch (action.type) {
		case 'LOGIN':
			return {
				...state,
				user: action.payload.user,
				token: action.payload.token,
				loading: false,
			};
		case 'UPDATE_USER':
			return { ...state, user: action.payload.user, loading: false };
		case 'LOGOUT':
			localStorage.removeItem('auth-token');
			return {
				...state,
				user: null,
				token: null,
				loading: false,
			};
		case 'SET_LOADING':
			return {
				...state,
				loading: action.payload,
			};
		default:
			return state;
	}
};

export default authReducer;
