import { useTheme } from '@emotion/react';
import {
  alpha,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import axios from 'axios';
import React, { useRef } from 'react';
import { useContext, useState, useEffect } from 'react';
import {
  Link,
  Link as RouterLink,
  useParams,
} from 'react-router-dom';
import AuthContext from '../../../context/auth/AuthContext';
import styled from '@emotion/styled';
import ReactTextMoreLess from 'react-text-more-less';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { ReactComponent as CaloriesIcon } from '../../../assets/icons/calories.svg';
import { ReactComponent as Clock } from '../../../assets/icons/clock.svg';
import { makeStyles } from '@mui/styles';

const identifier = window.location.hostname;

const useStyles = makeStyles((theme) => ({
  containerMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    flexGrow: '1',
    height: '100%',
    '@media  (orientation: landscape) and (max-height:610px) and (max-width:900px)':
      {
        paddingTop: '30px',
      },
  },
}));

const CategoryCover = () => {
  const params = useParams();
  const theme = useTheme();
  const [category, setCategory] = useState(null);
  const authContext = useContext(AuthContext);
  const { apiUrl, token } = authContext;
  const [totalVideos, setTotalVideos] = useState();
  const [allVideosLength, setAllVideosLength] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const [height, setHeight] = useState(300);
  const ref = useRef(null);
  const [allTextShowed, setAllTextShowed] = useState(false);
  const getCategory = async (id) => {
    return await axios.get(
      `${apiUrl}/v2/categories/${id}?reseller_domain=${identifier}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  //detect sm breakPoint
  const breakPointMD = useMediaQuery(theme.breakpoints.down('md'));

  //get the window dimensions to use it on window reasize for reactmoretext height
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  useEffect(() => {
    //set the height of the ReactTextMoreLess container on page load, when container is in DOM
    if (!ref.current) return;

    const handleResize = (props) => {
      // set the height
      setHeight(setTheReadmoreHeight(ref.current));

      //if the built in "showmore" element is shown,  setIfAllTextIsShowed
      //the "showmore" appears if the text is stripped
      setIfAllTextIsShowed(
        document.getElementsByClassName('js-shave-char')
      );
    };

    handleResize();

    //set height of ReactTextMoreLess depending on container height
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const setTheReadmoreHeight = (element) => {
    let currentContainerHeight = 300;
    if (element) {
      currentContainerHeight =
        element.clientHeight - 80 < 100
          ? 100
          : element.clientHeight - 80;
    }

    return breakPointMD ? 155 : currentContainerHeight;
  };

  //detect if element is visible or not
  const setIfAllTextIsShowed = (element) => {
    if (element.length > 0) {
      setAllTextShowed(false);
    } else {
      setAllTextShowed(true);
    }
  };

  //get the category
  useEffect(() => {
    if (!category) {
      getCategory(params.id).then((res) => {
        setCategory(res?.data?.data[0]);
      });
    }
    //eslint-disable-next-line
  }, []);

  //set the total videos that catefory has
  useEffect(() => {
    if (!category) return;
    //get all videos length to show  how many videos are in category
    let allVideosLengthNumber = getVideos(category).length;

    setAllVideosLength(allVideosLengthNumber);
  }, [category]);

  const getVideos = (cat) => {
    let videos = [];
    const traverse = (subcategory) => {
      ifKeyData(subcategory);
    };
    const ifKeyData = (subcategory) => {
      if (subcategory.videos) {
        videos = videos.concat(subcategory.videos);
      }
      if (subcategory.subcategories) {
        subcategory.subcategories.forEach(traverse);
      }
    };
    ifKeyData(cat);
    return videos;
  };

  useEffect(() => {
    if (allVideosLength || allVideosLength === 0) {
      if (allVideosLength === 0 || allVideosLength > 1) {
        setTotalVideos(`${allVideosLength} videos`);
      } else if (allVideosLength && allVideosLength === 1) {
        setTotalVideos(`${allVideosLength} video`);
      }
    }
  }, [allVideosLength]);

  //event for Load More button , set if it is collapsed or not
  const handleShowMore = () => {
    if (collapsed) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  };

  const Item = styled('span')(({ theme }) => ({
    textAlign: 'center',
    color: theme.palette.white.main,
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  }));

  const btnStyle = {
    fontFamily: 'Lato',
    fontWeight: '400',
    fontSize: {
      xs: '1rem',
      sm: '1.5rem',
      md: '1.8rem',
      lg: '2.1rem',
    },
    padding: {
      xs: '24px 15px',
      md: '28px 15px',
      lg: '38px 15px',
      xl: '45px 15px',
    },
    lineHeight: '1.2em',
    letterSpacing: '0.24px',
    borderRadius: '50px',
    height: 'auto',
    textTransform: 'none',
    boxShadow: 0,
  };
  const classes = useStyles();
  return (
    <>
      {category ? (
        <Grid
          className="Session"
          container
          sx={{
            flexGrow: '1',
            position: 'relative',
            alignItems: 'stretch',
            height: '100%',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'flex-end',
          }}
        >
          <Grid
            item
            xs={12}
            md={6}
            lg={7}
            sx={{
              height: { xs: '100%', md: '100%' },
              position: 'fixed',
              top: '0',
              left: '0px',
              width: '100%',
              maxWidth: 'inherit',
              p: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                flexGrow: '1',
                height: '100%',
                width: '100%',
                backgroundImage: `url("${
                  category.image
                    ? category.image
                    : 'https://source.unsplash.com/random?fitness'
                } ")`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                  t.palette.mode === 'light'
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                p: { xs: '64px 20px 38px 20px', sm: '55px' },
              }}
            >
              <Tooltip
                title="Close"
                sx={{
                  display: { xs: 'none', md: 'inline-flex' },
                  width: '55px',
                  height: '55px',
                  backgroundColor: 'lightButton.main',
                  color: 'lightButton.contrastText',
                  fontSize: '23px',
                  fontFamily: theme.typography.roboto,
                  // boxShadow: '0px 0px 6px rgba(0,0,0, 0.1)',
                  '& svg': {
                    color: 'lightButton.contrastText',
                    fill: 'lightButton.contrastText',
                    width: '20px',
                    height: '20px',
                  },
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: 'lightButton.contrastText',
                  },
                  '&:hover svg': {
                    color: 'lightButton.contrastText',
                    fill: 'lightButton.contrastText',
                  },
                }}
              >
                <IconButton
                  aria-label="Close Workout"
                  variant="outlined"
                  size="small"
                  component={RouterLink}
                  to="/"
                >
                  x
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            sx={{
              position: 'relative',
              zIndex: 1,
              background: {
                xs: `linear-gradient(180deg,   ${alpha(
                  theme.palette.blackBg.main,
                  0
                )} 0, ${alpha(
                  theme.palette.blackBg.main,
                  0.35
                )} 30%)`,

                md: theme.palette.common.black,
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white.main',
              minHeight: '100%',
            }}
          >
            <Box
              className={classes.containerMobile}
              sx={{
                p: { xs: '64px 20px 38px 20px', md: '0' },
              }}
            >
              <Box
                sx={{
                  display: { xs: 'flex', md: 'none' },
                }}
              >
                <Tooltip
                  title="Close"
                  sx={{
                    width: '55px',
                    height: '55px',
                    backgroundColor: 'transparent',
                    color: theme.palette.common.white,
                    fontSize: '23px',
                    fontFamily: theme.typography.roboto,
                    background: alpha(
                      theme.palette.common.black,
                      0.05
                    ),

                    '& svg': {
                      color: theme.palette.common.white,
                      fill: theme.palette.common.white,
                      width: '20px',
                      height: '20px',
                    },
                    '&:hover': {
                      backgroundColor: 'transparent',
                      color: theme.palette.common.white,
                    },
                    '&:hover svg': {
                      color: theme.palette.common.white,
                      fill: theme.palette.common.white,
                    },
                  }}
                >
                  <IconButton
                    aria-label="Close Workout"
                    variant="outlined"
                    size="small"
                    component={RouterLink}
                    to="/"
                  >
                    <ArrowLeft color="inherit" />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  flexGrow: { xs: 0, md: 1 },
                  height: { xs: 'auto', md: '100%' },
                  p: {
                    xs: '0',
                    md: '30px 20px 60px 20px',
                    lg: '30px 7% 60px 7%',
                    xl: '49px 62px 97px 62px',
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flex: 0,
                  }}
                >
                  {/* header */}
                  <Box
                    className="ctHeader"
                    sx={{
                      width: '100%',
                      flexGrow: 0,
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={'15px'}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexDirection: 'row',
                        flexGrow: 0,
                      }}
                    >
                      <Typography
                        variant="h1"
                        color={'white.main'}
                        sx={{
                          fontSize: { xs: '33px', lg: '54px' },
                          fontSize: {
                            xs: 'min(max(33px, 4.3vw), 58px)',
                          },
                          lineHeight: '1.25em',
                          fontWeight: '800',
                          mb: 0,
                        }}
                      >
                        {category.title}
                      </Typography>
                      <Chip
                        variant="contained"
                        color="primary"
                        label={totalVideos}
                        sx={{
                          fontSize: '27px',
                          fontSize: 'min(max(14px, 2.35vw), 27px)',
                          fontFamily: theme.typography.roboto,
                          p: {
                            xs: '8px 12px',
                            sm: '12px 25px',
                            xl: '18px 33.5px',
                          },
                          borderRadius: '50px',
                          textTransform: 'none',
                          height: 'auto',
                          fontWeight: 'bold',
                          letterSpacing: '0.15px',
                          lineHeight: '1.2em',
                        }}
                      />
                    </Stack>

                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                        flexDirection: 'row',
                        flexGrow: 0,
                        mt: { xs: '0.875rem', md: '1.8rem' },
                      }}
                    >
                      <Typography
                        variant="h1"
                        color={alpha(theme.palette.common.white, 0.8)}
                        sx={{
                          fontSize: { xs: '14px', lg: '25px' },
                          fontFamily: theme.typography.lato,
                          lineHeight: '1.25em',
                          fontWeight: '400',
                          mb: 0,
                        }}
                      >
                        {category.author}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={'22px'}
                        sx={{
                          fontSize: {
                            xs: '14px',
                            md: '18px',
                            lg: '22px',
                          },
                          alignItems: 'center',
                        }}
                      >

                        {/* Can't use condition category.total_kcal && , because it will break functionality, and will show "0"  */}
                       {category.total_kcal ? (
                          <Item className="calories">
                            <CaloriesIcon />
                            <Typography
                              component="span"
                              variant="p"
                              sx={{ fontWeight: 'bolder' }}
                            >
                              {category.total_kcal}
                            </Typography>
                            <Typography
                              component="span"
                              variant="p"
                              sx={{ fontWeight: 'normal' }}
                            >
                              kcal
                            </Typography>
                          </Item>
                        ) :('')}
                        {category.total_duration ? (
                          <Item className="time">
                            <Clock />
                            <Typography
                              component="span"
                              variant="p"
                              sx={{ fontWeight: 'bolder' }}
                            >
                              {category.total_duration}
                            </Typography>
                            <Typography
                              component="span"
                              variant="p"
                              sx={{ fontWeight: 'normal' }}
                            >
                              min
                            </Typography>
                          </Item>
                        ) : ('')}
                      </Stack>
                    </Box>
                  </Box>
                </Box>
                {/* content */}
                <Box
                  className="ctContent"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    justifyContent: 'center',
                    flexGrow: '1',
                    width: '100%',
                    flexGrow: 1,
                    my: { xs: '20px', md: '5vh' },
                  }}
                >
                  <Box
                    sx={{
                      position: 'relative',
                      display: 'flex',
                      width: '100%',
                      flexGrow: 1,
                      minHeight: category.description ? '150px' : '0',
                    }}
                  >
                    <Box
                      ref={ref}
                      className="showMoreContainer"
                      sx={{
                        maxHeight: '100%',
                        overflow: {
                          xs: 'initial',
                          md: collapsed ? 'hidden' : 'visible',
                        },
                        position: {
                          xs: 'initial',
                          md: collapsed ? 'absolute' : 'relative',
                        },
                        display: 'flex',
                        alignItems: 'center',
                        top: '0px',
                        left: '0px',
                        width: '100%',
                        height: '100%',

                        '& .content-css': {
                          color: 'inherit',
                          fontSize: {
                            xs: '14px',
                            sm: '1.2rem',
                            md: '1.5rem',
                            lg: '1.7rem',
                            xl: '2rem',
                          },
                          fontWeight: { xs: '300', md: '400' },
                          textDecoration: 'none',
                          textTransform: 'none',
                          fontFamily: theme.typography.lato,
                          display: 'block',
                          textAlign: 'left',
                          maskImage: collapsed
                            ? allTextShowed
                              ? `linear-gradient(to bottom, ${
                                  theme.palette.common.black
                                } 0%, ${alpha(
                                  theme.palette.common.black,
                                  0.24
                                )} 100%)`
                              : `linear-gradient(to bottom, ${
                                  theme.palette.common.black
                                } 9%,  ${alpha(
                                  theme.palette.common.black,
                                  0.07
                                )} 100%)`
                            : 'transparent',
                        },
                      }}
                    >
                      {category.description ? (
                        <ReactTextMoreLess
                          className="content-css"
                          collapsed={collapsed}
                          text={category.description}
                          //text={hardcodedCattext}
                          lessHeight={height}
                        />
                      ) : (
                        ''
                      )}
                    </Box>
                  </Box>
                  <Button
                    className="what"
                    variant="text"
                    onClick={handleShowMore}
                    sx={{
                      color: 'inherit',
                      fontSize: {
                        xs: '18px',
                        md: '22px',
                        lg: '27px',
                      },
                      textDecoration: 'none',
                      textTransform: 'none',
                      fontFamily: theme.typography.lato,
                      marginTop: '6%',
                      display:
                        allTextShowed && collapsed ? 'none' : 'block',
                    }}
                  >
                    {collapsed
                      ? allTextShowed
                        ? ''
                        : 'Show More '
                      : 'Show Less'}
                  </Button>
                </Box>
                {/* footer */}

                <Box
                  sx={{
                    my: 1,
                    mx: { xs: '0', md: '8px' },
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-end',
                    justifyContent: 'end',

                    flexGrow: 0,
                  }}
                >
                  {allVideosLength > 0 ? (
                    <Button
                      fullWidth
                      variant="contained"
                      size="large"
                      sx={btnStyle}
                      component={RouterLink}
                      to={`/workout/${category.id}/videos/`}
                    >
                      Start Workout
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Stack
          direction={'row'}
          spacing={'15px'}
          sx={{
            alignItems: 'stretch',
            height: '100%',
            padding: { xs: '20px', md: '0px' },
          }}
        >
          <Skeleton
            variant="rectangular"
            sx={{
              height: '100%',
              width: { xs: '100%', md: '60%' },
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              height: '100%',
              display: { xs: 'none', md: 'block' },
            }}
            width="40%"
          />
        </Stack>
      )}
    </>
  );
};

export default CategoryCover;
