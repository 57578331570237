import React from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ifMainColorIsLight, isLight } from '../../helpers/functions';


const CaloriesHistory = ({history}) => {
    const nowDate =   dayjs().format('YYYY-MM-DD');
    const theme =useTheme()
    
    const sortedHistory = Object.entries(history).sort((a, b) => new Date(b[0]) - new Date(a[0]));    
    const maxVal = Math.max(...sortedHistory.map(([_, value]) => value));
    //css
    const theadCss={ fontWeight:'900',color: ifMainColorIsLight(theme),px:1}
    const tdCss={px:1,py:2}  
    
    return (
        <Box sx={{width:'100%' ,pt:2 }}>
            
            <Accordion disableGutters={true} sx={{ padding:0 ,border:`1px solid ${theme.palette.lightButton.main}`}} elevation={0} >
                <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{ backgroundColor :"lightButton.main", }}
                >
                <Typography variant="p" sx={{ fontFamily:theme.typography.roboto,fontSize: { xs: '15px', md: '18px' },
                        fontWeight: 500,}} >
                        Calories History
                    </Typography>
                </AccordionSummary>

                <AccordionDetails sx={{ px:0 }}>
                    <TableContainer component={Paper} sx={{ mt:1,border:'0px '}} elevation={0}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell sx={{ ...theadCss }}>Date</TableCell>
                                <TableCell sx={{ ...theadCss }} align="right">Exercise Calories</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>

                                {sortedHistory.map(([date, value]) => {
                                                     
                                   if(date !== nowDate ) return (
                                    <TableRow
                                        key={date}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }}}
                                        >
                                        <TableCell component="th" scope="row" sx={{ ...tdCss }}>
                                            {date}
                                        </TableCell>
                                        <TableCell align="right" sx={{ ...tdCss,color:value === maxVal? ifMainColorIsLight(theme) : 'inherit', fontWeight:value === maxVal? 'bold':'normal' }}>{value}</TableCell>
                                    
                                    </TableRow>
                                )}
                            
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>

        </Box>
    );
};

export default CaloriesHistory;