import React from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button, Stack, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import UserCalories from './UserCalories';
import { useTheme } from '@emotion/react';
import { useContext } from 'react';
import AuthContext from '../../context/auth/AuthContext';

import { alpha } from '@mui/material';
import PageContext from '../../context/page/PageContext';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import CircularAvatar from './CircularAvatar';

const HomeHeader = ({title, subtitle}) => {
    const theme = useTheme();
    const { user,apiUrl, token } = useContext(AuthContext);
    const { page } = useContext(PageContext);

    const [goalCalories, setGoalCalories] = useState(0);
    const [sliderAchieved, setSliderAchieved] = useState(0);
    const [userImage,setUserImage]= useState();

    const handleUserCaloriesCallback =(childData) =>{
        const {sliderAchieved,goalCalories,userImage} = childData || {}

        if(sliderAchieved) setSliderAchieved(sliderAchieved)
        if(goalCalories) setGoalCalories(goalCalories);
        if(userImage) setUserImage(userImage)
        
    }
    
    const isMD= useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Stack display="flex" direction="row" alignItems={'stretch'}
             sx={{ flexWrap:{xs:'wrap',md:'nowrap'},position:'relative' }} >
            <Stack direction="column" spacing={'15px'} alignItems="end" justifyContent="start" sx={{                    
                position:'absolute',
                right:0,
                top:0,
                width:'100% '}}>
                    
                    <Tooltip title="Logout">
                    <Button
                        size="small"
                        variant="text"
                        onClick={() => window.logout()}
                        sx={{
                        padding: '8px',
                        minWidth: 'unset',
                        borderRadius: '50%',
                        backgroundColor: alpha(
                            theme.palette.iconDarkDefault.main,
                            0.6
                        ),
                        color: theme.palette.common.white,
                        // boxShadow: '0px 0px 6px rgba(0,0,0, 0.1)',
                        '& svg': {
                            color: theme.palette.common.white,
                            fill: theme.palette.common.white,
                            width: '22px',
                            height: '22px',
                            fontSize: '20px',
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: theme.palette.iconDarkDefault.main,
                        },
                        '&:hover svg': {
                            color: theme.palette.iconDarkDefault.main,
                            fill: theme.palette.iconDarkDefault.main,
                        },
                        }}
                    >
                        <LogoutIcon inheritViewBox fontSize={'20px'} />
                    </Button>
                    </Tooltip>
                </Stack>
                
            
                <Stack
                display="flex"
                justifyContent="space-between"
                sx={{ 
                    mb: 0,
                    flexShrink:0,
                    flexGrow:{xs:1,md:0},
                    maxWidth:{xs:'100%',md:'40%',xl:'29%'},
                    alignItems:{xs:"center",md:"start"},                    
                  
                }}
                > 
                
                    <Box  sx={{ width:'100%' }}>

                        {/* Logo image */}
                        <Button
                            component={Link}
                            to="/"
                            sx={{
                            width: { xs: '100%', sm: 250,lg:300 },
                            maxWidth: { xs: 190, sm: 250,lg:300  },
                            height: { xs:70, sm: 86 ,lg:100},
                            p: 0,
                            pr: 1,
                            display: 'block',
                            marginBottom: {xs:'0px',xl:'24px'}
                            }}
                        >
                            <img
                            alt="Remy Sharp"
                            src={page.logo}
                            variant="square"
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                objectPosition: 'left ',
                                display: 'block',
                            }}
                            />
                        </Button>

                        {/* greeting text */}
                        <Box sx={{ width:'100%' }}>
                        {isMD? (
                            <CircularAvatar sliderAchieved={sliderAchieved} goalCalories={goalCalories} userImage={userImage}/>
                            ):('')}
                            <Typography
                            component="h1"
                            variant="h1"
                            sx={{
                                fontSize: ['35px','35px','35px','55px'],
                                lineHeight: '1.25em',
                                fontWeight: '800',
                                textTransform: 'capitalize',
                                textAlign:{xs:'center',md:'left'}
                            }}
                            >
                                <Typography component={'span'} color="#959292" sx={{  fontWeight: '800',fontFamily:'inherit' }}>
                                    Welcome
                                </Typography>
                            {!title ? `, ${user.firstname}!` : title}
                            </Typography>
                            <Typography
                            component="p"
                            sx={{
                                fontFamily: '"Poppins", sans-serif',
                                fontSize: { xs: '14px', md:'17px',lg: '22px' },
                                pl:{xs:'0.5rem',lg:'1.5rem'},
                                display:{xs:'none',md:'inline-block'}
                            }}
                            color="#959292"
                            >
                            {!subtitle ? 'Keep Moving & Stay Healthy' : subtitle}
                            </Typography>
                        </Box>
                    
                    </Box>
                
                </Stack>
            <Stack
                display="flex"
                direction="row" 
                justifyContent="stretch"
                sx={{ mb: 0,flexWrap:'wrap',flexGrow:1,justifyContent:['start','start','end'],width:{xs:'100%', md:'auto'}}}
            >       
                <Stack direction="column" flexGrow={1} justifyContent={'center'}>
                                
                    <UserCalories theme={theme} callback={handleUserCaloriesCallback} />
                    
                </Stack>

                
            </Stack>
        </Stack>
    );
};

export default HomeHeader;