import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { ifMainColorIsLight, isLight } from '../helpers/functions';
import { useTheme } from '@emotion/react';


export default function BasicModal({data}) {

  const {openVal,contentText,title,parentCallback,dialogFields,onSubmit,dataSubmit} = data || {};
  const [modalOpen, setModalOpen] = useState(openVal? openVal : false);
  const theme=useTheme()

  const handleClose = () => {
     setModalOpen(false);
  }

  const handleSave = () => {
    onSubmit(dataSubmit? dataSubmit: null)
 }
  //trigger whe openVal (parent value ) changes
  useEffect(() => {
    openVal ? setModalOpen(openVal) : setModalOpen(false);
   
  }, [openVal])

  //trigger when modal open value changes
  useEffect(() => {
      //send open val tu parent
      if (parentCallback) {
        parentCallback({modalOpen : modalOpen});
      }
  
  }, [modalOpen])


  return (     
   
      <Dialog open={modalOpen} onClose={handleClose} disableScrollLock={ true }>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent >

              <DialogContentText>
              {contentText}
              </DialogContentText>

              <Box sx={{ p:1,width:'100%' }} >

                {dialogFields}
              </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} sx={{ color:ifMainColorIsLight(theme) }}>Cancel</Button>
          <Button onClick={handleSave} variant={'contained'} sx={{ background:ifMainColorIsLight(theme) }}>Save</Button>
        </DialogActions>
    </Dialog>
   
  );
}