import { useTheme } from '@emotion/react';
import { LocalFireDepartment } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';

const IntensityCategories = ({intensityCategories,videoPlaying}) => {
  
  const theme=useTheme(); 
  const videoIntensityId = videoPlaying.intensity.id ? videoPlaying.intensity.id : false
    return (
        <>
        {intensityCategories && intensityCategories.length > 0 ? (
            <Stack direction="row" className="intensityCategories" spacing={{ xs:'5px', md:'8px' }}  alignItems={'center'} justifyContent={'start'} sx={{ flexWrap:'wrap',flexGrow:1}}>
                 {intensityCategories.map((intensity, index) => {
                  return(
                  <Stack key={index} direction="row" className="intensityCategories" spacing={{ xs:'5px', md:'8px' }}>
                      {index === 0 && (
                        <Typography component={'span'} sx={{ color: theme.palette.disabled.main }}>
                          |
                        </Typography>
                      )}

                    <Typography component={'span'} sx={{ 
                      textTransform:'capitalize',
                      color: videoIntensityId && intensity.id === videoIntensityId ? theme.palette.primary.main :  theme.palette.disabled.main,
                      fontWeight:videoIntensityId && intensity.id === videoIntensityId ? 'bold' : 'normal',
                      fontSize: {xs:'15px',md: '17px',lg:'18px'} }}>
                    
                    {intensity.name} {' '}                                   
                    
                    </Typography>

                    <Typography component={'span'}  sx={{ color: theme.palette.disabled.main }}>
                       |
                    </Typography>
                    
                  </Stack>
                  )
                 })}

                 
              
            </Stack>
          ):('')}
        </>
    );
};

export default IntensityCategories;