import React, { useEffect } from 'react';
import { createContext, useReducer } from 'react';
import authReducer from './AuthReducer';
import axios from 'axios';
import { toast } from 'react-toastify';
const AuthContext = createContext();
// const API_URL = process.env.API_URL;
const identifier = window.location.hostname;

const apiUrl = process.env.REACT_APP_API_URL;
export const AuthProvider = ({ children }) => {
	const initialState = {
		user: null,
		token: null,
		loading: false,
	};
	const getLoggedInUserBasedOnToken = (token) => {
		// console.log('called token login');
		axios
			.get(`${apiUrl}/user`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			.then((res) => {
				// console.log(res);
				if (res) {
					const response = res.data;
					dispatch({
						type: 'LOGIN',
						payload: { user: response.data[0], token: token },
					});
				}
			})
			.catch((res) => {
				// console.log(res);
				logout();
				toast.error('Session expired, please login again');
				setLoading(false);
			});
	};
	useEffect(() => {
		const token = localStorage.getItem('auth-token');
		// attempt to relogin after hard page refresh
		if (token) {
			getLoggedInUserBasedOnToken(token);
		}
	}, []);
	const [state, dispatch] = useReducer(authReducer, initialState);

	// Get single user
	const login = async (data) => {
		setLoading(true);
		axios
			.post(`${apiUrl}/login`, { ...data })
			.then((res) => {
				// console.log(res);
				if (res) {
					const response = res.data;
					dispatch({
						type: 'LOGIN',
						payload: response,
					});
					localStorage.setItem('auth-token', response.token);
				}
			})
			.catch((res) => {
				toast.error(
					res.response.data.error
						? res.response.data.error
						: res.response.data.message
				);
				setLoading(false);
			});
		return;
	};

	// Clear users from state

	const logout = () => dispatch({ type: 'LOGOUT' });
	const whoAmI = async () => {
		const token = localStorage.getItem('auth-token');
		// attempt to relogin after hard page refresh
		if (token) {
			return axios
				.get(`${apiUrl}/user`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				})
				.then((x) => {
					updateUser(x.data.data[0]);
					return x.data.data[0];
				});
		}
		return null;
	};
	const updateUser = (usr) => {
		dispatch({
			type: 'UPDATE_USER',
			payload: { user: usr },
		});
	};
	// Set loading
	const setLoading = (value) =>
		dispatch({ type: 'SET_LOADING', payload: value });
	const resetPw = (data) => {
		setLoading(true);
		// console.log(data);
		axios
			.post(`${apiUrl}/password/email`, {
				email: data.email,
				code: identifier,
				successUrl:
					window.location.protocol +
					'//' +
					window.location.host +
					'/reset-password',
					
			})
			.then((res) => {
				// console.log(res);
				if (res) {
					const response = res.data;
					// console.log(response);
					toast.success(response.message);
					setLoading(false);
				}
			})
			.catch((res) => {
				// console.log(res);
				toast.error(
					res.response.data.error?.email
						? res.response.data.error.email[0]
						: res.response.data.message
				);
				setLoading(false);
			});
	};
	window.logout = logout;
	return (
		<AuthContext.Provider
			value={{
				loading: state.loading,
				user: state.user,
				token: state.token,
				apiUrl,
				setLoading,
				logout,
				login,
				resetPw,
				updateUser,
				whoAmI,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
